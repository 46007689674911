.box__category_default-padding {
  @media #{$mobiles} {
    padding: 1rem;
  }
}
.box__category {
  display: flex;
  flex-direction: column;
  &_breadcrumb {
    margin-bottom: 3rem;
  }
  &_content {
    display: flex;
    @media #{$mobiles} {
      flex-direction: column;
    }
    @media #{$large-desktops} {
      flex-direction: row;
    }
    @media screen and (min-width: 768px) and (max-width: 1023px) {
      flex-direction: column;
    }
    .box__category_content--mainAvatar {
      height: 60rem;
      @media #{$large-desktops} {
        width: 100%;
      }
      img {
        display: block;
        @media screen and (min-width: 375px) and (max-width: 767px) {
          width: 100%;
        }
        @media #{$large-desktops} {
          height: 100%;
          display: block;
        }
      }
    }
    .box__category_showListAvatar {
      display: flex;
      flex-direction: column;
      width: 100%;
      @media #{$mobiles} {
        padding: 1rem;
      }
      @media #{$large-desktops} {
        padding: 1.2rem 0 5rem 3.3rem;
      }
      &--title {
        width: 100%;
        font-size: 2rem;
        font-weight: 400;
        line-height: 2.344rem;
        color: #111947;
        text-align: left;
        vertical-align: middle;
        margin-top: 3.1rem;
        margin-bottom: 3.1rem;
      }
      &--list {
        @media #{$mobiles} {
          display: grid;
          grid-template-columns: repeat(2, 1fr);
          padding: 1rem;
          grid-column-gap: 2.5rem;
          grid-row-gap: 2rem;
        }
        @media screen and (min-width: 768px) and (max-width: 1023px) {
          grid-template-columns: repeat(5, 1fr);
          grid-column-gap: 1.5rem;
          padding: 0;
          padding-bottom: 1rem;
        }
        @media #{$large-desktops} {
          display: flex;
          margin-bottom: 4.3rem;
        }
        .active {
          border: 1px solid $blue-main;
          border-radius: 1.1rem;
          #item-active {
            padding: 0.3rem;
          }
        }
        .item {
          margin-right: 2rem;
          @media #{$mobiles} {
            margin-right: 0;
          }
          @media screen and (min-width: 768px) and (max-width: 1023px) {
            margin-right: 2rem;
          }
          img {
            display: block;
            border-radius: 1.1rem;
            @media screen and (min-width: 375px) and (max-width: 765px) {
              width: 100%;
            }
          }
        }
      }
      &--select {
        width: 100%;
        text-align: left;
        margin-bottom: 2.7rem;
        padding-right: -10rem;
        .label {
          font-size: 2rem;
          font-size: 400;
          line-height: 2.344rem;
          color: #111947;
          vertical-align: middle;
          margin-bottom: 0.9rem;
        }
      }
      .buy {
        text-align: right;
        border-top: 1px solid $gray-divider;
        border-radius: 0.5rem;
        .box__category_showListAvatar--price {
          padding-top: 2.3rem;
          margin-bottom: 2.3rem;
          font-size: 3.6rem;
          font-weight: 400;
          line-height: 4.219rem;
          color: $color-text-normal;
          vertical-align: middle;
        }
        .box__category_showListAvatar--btnDownload {
          color: #fff;
          display: inline-block;
          padding: 1.8rem 9rem;
          background-color: $blue-main;
          font-size: 1.4rem;
          border-radius: 5rem;
        }
      }
    }
  }
}
