.my-page-button {
  width: calc(220vw / 4.14);
  height: calc(43vw / 4.14);
  font-size: calc(16vw / 4.14);
  line-height: 1.05;
  border-radius: 1.5em;
  padding: 0.25em;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-width: 0;
  outline: none;
  background-color: $blue-main;
  color: $white;
  font-family: Montserrat;
  font-weight: 500;
  cursor: pointer;
  @media #{$tablets} {
    font-size: calc(16vw / 14);
    font-weight: 500;
    width: calc(180vw / 14);
    height: calc(43vw / 14);
  }
  @media #{$large-desktops} {
    font-size: 1.6rem;
    width: 18rem;
    height: 4.3rem;
  }
  &__represent {
    height: calc(35vw / 4.14);
    width: calc(35vw / 4.14);
    border-radius: 50%;
    @media #{$tablets} {
      height: calc(35vw / 14);
      width: calc(35vw / 14);
    }
    @media #{$large-desktops} {
      height: 3.5rem;
      width: 3.5rem;
    }
  }
  &__label {
    flex: 1;
  }
  
  
}
