$credit-active-background: #f0ffff;
$border-table: #9f9f9f;
$background-table: #f9f9f9;
$background-header: $color-text-normal;
$brand-pink: #ff6c6c;
$credit-background: #f5f5f5;

.download-avatar-user {
  box-sizing: border-box;
  &__for-test-purpose {
    top: 10rem;
    right: 0;
    width: 40rem;
    label {
      color: red;
    }
  }
  &__container {
    margin-bottom: 2.8rem;
    .wrapper__header {
      border: none;
    }
  }
  &__inner-container {
    @media #{$tablets} {
      display: grid;
      grid-template-columns: repeat(3, minmax(0, 1fr));
      column-gap: 3rem;
    }
    form {
      @media #{$tablets} {
        grid-column: 2 / 4;
      }
    }
  }
  &__sidebar-area {
    .wrap-render_avatar-detail {
      width: 100%;
      .wrap-icon {
        position: absolute;
        top: calc(10vw / 4.14);
        left: calc(10vw / 4.14);
        width: calc(24vw / 4.14);
        height: calc(24vw / 4.14);
      }
      @media #{$tablets} {
        .wrap-icon {
          position: absolute;
          top: calc(10vw / 14);
          left: calc(10vw / 14);
          width: calc(24vw / 14);
          height: calc(24vw / 14);
        }
      }
      @media #{$large-desktops} {
        .wrap-icon {
          position: absolute;
          top: 1rem;
          left: 1rem;
          width: 2.4rem;
          height: 2.4rem;
        }
      }
    }
  }
  &__selection {
    margin-bottom: calc(15vw / 4.14);
    .input {
      width: 100%;
      input {
        width: 100%;
        border-color: $border-input !important;
      }
    }
    @media #{$tablets} {
      margin-bottom: calc(15vw / 14);
    }
    @media #{$large-desktops} {
      margin-bottom: 1.5rem;
    }
  }
  &__content {
    margin-top: calc(30vw / 4.14);
    @media #{$tablets} {
      margin-top: 0;
      grid-column: 2 / 4;
    }
  }
  &__avatar-info {
    background-color: $background-table;
    border-radius: calc(10vw / 4.14);
    padding: calc(20vw / 4.14);
    @media #{$tablets} {
      border-radius: calc(10vw / 14);
      padding: calc(20vw / 14);
    }
    @media #{$large-desktops} {
      border-radius: 1rem;
      padding: 2rem;
    }
  }

  &__avatar-name {
    margin-bottom: calc(20vw / 4.14);
    &-ttle {
      font-size: calc(18vw / 4.14);
      font-weight: 700;
      color: $body-text;
      margin-bottom: calc(10vw / 4.14);
    }
    @media #{$tablets} {
      margin-bottom: calc(20vw / 14);
      &-ttle {
        font-size: calc(18vw / 14);
        margin-bottom: calc(10vw / 14);
      }
    }
    @media #{$large-desktops} {
      margin-bottom: 2rem;
      &-ttle {
        font-size: 1.6rem;
        margin-bottom: 1rem;
      }
    }
  }

  &__avatar-table {
    display: flex;
    flex-direction: column;
    border-radius: calc(5vw / 4.14);
    overflow: hidden;
    box-sizing: border-box;
    background: #fff;
    border: 1px solid $border-table;
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
      border-radius: calc(5vw / 14);
    }
    @media #{$large-desktops} {
      border-radius: 0.5rem;
    }

    &__group {
      display: flex;
      flex-direction: row;
      @media #{$tablets} {
        flex-direction: column;
        height: auto;
        &:nth-child(1) {
          flex: 2.5;
        }
        &:nth-child(2) {
          flex: 3;
        }
        &:nth-child(3) {
          flex: 2;
        }
        &:nth-child(4) {
          flex: 3;
        }
        &:nth-child(5) {
          flex: 6;
        }
      }
    }
    &__group:first-child {
      border-top-right-radius: calc(5vw / 4.14);
      @media #{$tablets} {
        border-top-right-radius: 0;
        border-bottom-left-radius: calc(5vw / 14);
      }
      @media #{$large-desktops} {
        border-top-right-radius: 0;
        border-bottom-left-radius: 0.5rem;
      }
    }
    &__group:last-child {
      border-bottom-right-radius: calc(5vw / 4.14);
      @media #{$tablets} {
        border-bottom-right-radius: calc(5vw / 14);
      }
      @media #{$large-desktops} {
        border-bottom-right-radius: 0.5rem;
      }
    }

    &__group-th {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: $white;
      line-height: 1.5;
      box-sizing: border-box;
      background-color: $background-header;
      width: 50%;
      padding: calc(10vw / 4.14);
      font-size: calc(14vw / 4.14);
      @media #{$tablets} {
        width: auto;
        padding-left: 0;
        justify-content: center;
        padding: calc(10vw / 14) 0;
        font-size: calc(14vw / 14);
      }
      @media #{$large-desktops} {
        padding: 1rem 0;
        font-size: 1.4rem;
      }
    }
    &__group-td {
      color: $body-text;
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 50%;
      padding: calc(10vw / 4.14);
      font-size: calc(14vw / 4.14);
      line-height: 1.5;
      @media #{$tablets} {
        justify-content: center;
        padding-left: 0;
        width: 100%;
        padding: calc(10vw / 14) 0;
        font-size: calc(14vw / 14);
      }
      @media #{$large-desktops} {
        padding: 1rem;
        font-size: 1.4rem;
      }
    }
    &__group-td1 {
      @media #{$tablets} {
        width: 26.6rem !important;
      }
    }
    & > * + * {
      border-top: none;
      border-left: 1px solid $border-table;
    }
  }
  &__file-format {
    padding-bottom: calc(20vw / 4.14);
    border-bottom: 1px solid $gray-divider;
    margin-bottom: calc(20vw / 4.14);
    &-label {
      color: $body-text;
      font-size: calc(16vw / 4.14);
      padding-bottom: calc(15vw / 4.14);
    }
    &-select {
      flex: 1;
      position: relative;
      .input {
        input {
          background: #fff;
          height: calc(40vw / 4.14);
          width: 100%;
          font-size: calc(16vw / 4.14);
        }
        .css-dyr6gj-container {
          [class$='control'] {
            height: calc(40vw / 4.14);
          }
          [class$='ValueContainer'] {
            font-size: calc(16vw / 4.14);
          }
          [class$='indicatorContainer'] {
            svg {
              width: calc(20vw / 4.14);
              height: calc(20vw / 4.14);
            }
          }
          [class$='menu'] {
            span {
              font-size: calc(16vw / 4.14);
            }
          }
          [class$='MenuList'] {
            & > div {
              font-size: calc(16vw / 4.14);
              padding: calc(15vw / 4.14);
            }
          }
        }
      }
      .form-field-error-message {
        width: auto;
        font-size: calc(16vw / 4.14);
        position: absolute;
        top: 50%;
        transform: translate(0, -50%);
        margin: 0;
        left: calc(20vw / 4.14);
      }
    }
    @media #{$tablets} {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-bottom: calc(20vw / 14);
      margin-bottom: calc(20vw / 14);
      &-label {
        font-size: calc(16vw / 14);
        margin-right: calc(20vw / 4.14);
        padding-bottom: 0;
      }
      &-select {
        margin-left: auto;
        .input {
          margin-bottom: 0;
          input {
            font-size: calc(16vw / 14);
            height: calc(40vw / 14);
          }
          .css-dyr6gj-container {
            [class$='control'] {
              height: calc(40vw / 14);
            }
            [class$='ValueContainer'] {
              font-size: calc(16vw / 14);
            }
            [class$='indicatorContainer'] {
              svg {
                width: calc(20vw / 14);
                height: calc(20vw / 14);
              }
            }
            [class$='menu'] {
              span {
                font-size: calc(16vw / 14);
              }
            }
            [class$='MenuList'] {
              & > div {
                font-size: calc(16vw / 14);
                padding: calc(15vw / 14);
              }
            }
          }
        }
        .form-field-error-message {
          font-size: calc(16vw / 14);
          left: calc(20vw / 14);
        }
      }
    }
    @media #{$large-desktops} {
      padding-bottom: 2rem;
      margin-bottom: 2rem;
      &-label {
        font-size: 1.6rem;
        margin-right: 2rem;
      }
      &-select {
        margin-left: auto;
        max-width: 40rem;
        .input {
          input {
            font-size: 1.6rem;
            height: 4rem;
          }
          .css-dyr6gj-container {
            [class$='control'] {
              height: 4rem;
            }
            [class$='ValueContainer'] {
              font-size: 1.6rem;
            }
            [class$='indicatorContainer'] {
              svg {
                width: 2rem;
                height: 2rem;
              }
            }
            [class$='menu'] {
              span {
                font-size: 1.6rem;
              }
            }
            [class$='MenuList'] {
              & > div {
                font-size: 1.6rem;
                padding: 1.5rem;
              }
            }
          }
        }
        .form-field-error-message {
          font-size: 1.6rem;
          left: 2rem;
        }
      }
    }
  }
  &__price-area {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
  }
  &__price-label {
    font-size: calc(16vw / 4.14);
    padding-right: calc(20vw / 4.14);
    font-weight: 700;
    color: $body-text;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      padding-right: calc(20vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
      padding-right: 2rem;
    }
  }
  &__price {
    color: $brand-pink;
    font-size: calc(24vw / 4.14);
    font-weight: 700;
    @media #{$tablets} {
      font-size: calc(24vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 2.4rem;
    }
  }
  &__credit-info {
    margin-top: calc(30vw / 4.14);
    @media #{$tablets} {
      margin-top: calc(30vw / 14);
    }
    @media #{$large-desktops} {
      margin-top: 3rem;
    }
  }
  &__credit-label {
    font-size: calc(20vw / 4.14);
    margin-bottom: calc(10vw / 4.14);
    color: $body-text;
    @media #{$tablets} {
      font-size: calc(20vw / 14);
      margin-bottom: calc(10vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 2rem;
      margin-bottom: 1rem;
    }
  }
  &__credit-describe {
    color: $body-text;
    line-height: 1.75;
    font-size: calc(16vw / 4.14);
    margin-bottom: calc(20vw / 4.14);
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      margin-bottom: calc(20vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
      margin-bottom: 2rem;
    }
  }
  &__credit-list {
    margin-bottom: calc(30vw / 4.14);
    @media #{$tablets} {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: calc(10vw / 14);
      margin-bottom: calc(30vw / 14);
    }
    @media #{$large-desktops} {
      gap: 1rem;
      margin-bottom: 3rem;
    }
  }
  &__credit-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background-color: $credit-background;
    border-radius: calc(5vw / 4.14);
    padding-left: calc(10vw / 4.14);
    padding-right: calc(20vw / 4.14);
    height: calc(50vw / 4.14);
    margin-bottom: calc(10vw / 4.14);
    @media #{$tablets} {
      border-radius: calc(5vw / 14);
      padding-left: calc(10vw / 14);
      padding-right: calc(20vw / 14);
      height: calc(50vw / 14);
      margin-bottom: calc(10vw / 14);
    }
    @media #{$large-desktops} {
      border-radius: 0.5rem;
      padding-left: 1rem;
      padding-right: 2rem;
      height: 5rem;
      margin-bottom: 1rem;
    }
  }
  &__credit-couple {
    display: flex;
    flex-direction: row;
    align-items: center;
    .input {
      margin-bottom: 0;
    }
    svg {
      width: calc(24vw / 4.14);
      height: calc(24vw / 4.14);
      vertical-align: bottom;
      cursor: pointer;
    }
    @media #{$tablets} {
      svg {
        width: calc(24vw / 14);
        height: calc(24vw / 14);
      }
    }
    @media #{$large-desktops} {
      svg {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  &__credit-brand,
  &__credit-series {
    margin-left: calc(15vw / 4.14);
    font-size: calc(16vw / 4.14);
    letter-spacing: calc(1vw / 4.14);
    color: $body-text;
    font-family: 'Roboto';
    @media #{$tablets} {
      margin-left: calc(15vw / 14);
      font-size: calc(16vw / 14);
      letter-spacing: calc(1vw / 14);
    }
    @media #{$large-desktops} {
      margin-left: 1.5rem;
      font-size: 1.4rem;
      letter-spacing: 0.01rem;
    }
  }
  &__credit-series {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    > span {
      white-space: nowrap;
    }
  }
  &__credit-add {
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: $credit-background;
    height: calc(50vw / 4.14);
    padding-left: calc(10vw / 4.14);
    border-radius: calc(5vw / 4.14);
    cursor: pointer;
    svg {
      width: calc(30vw / 4.14);
      height: calc(30vw / 4.14);
      vertical-align: bottom;
    }
    @media #{$tablets} {
      height: calc(50vw / 14);
      padding-left: calc(10vw / 14);
      border-radius: calc(5vw / 14);
      svg {
        width: calc(30vw / 14);
        height: calc(30vw / 14);
      }
    }
    @media #{$large-desktops} {
      height: 5rem;
      padding-left: 1rem;
      border-radius: 0.5rem;
      svg {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  &__credit-add-content {
    padding-left: calc(10vw / 4.14);
    font-size: calc(16vw / 4.14);
    font-family: 'Roboto';
    color: $color-text-normal;
    @media #{$tablets} {
      padding-left: calc(10vw / 14);
      font-size: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      padding-left: 1rem;
      font-size: 1.6rem;
    }
  }
  &__submit {
    text-align: center;
    margin: 0 auto;
    width: calc(240vw / 4.14);
    @media #{$tablets} {
      text-align: right;
      width: calc(240vw / 14);
      margin: 0 0 0 auto;
    }
    @media #{$large-desktops} {
      width: 24rem;
    }
  }
  &__submit-button {
    background-color: $blue-main;
    color: $white;
    width: 100%;
    transition: background-color 0.3s ease-in-out;
  }
}

.modal1-download-avatar {
  display: flex;
  justify-content: space-between;
  &:not(:last-child) {
    padding-bottom: 15px;
    border-bottom: 1px solid #ccc;
  }
  &:last-child {
    padding-top: 15px;
  }
  &__content2 {
    color: #ff6c6c;
    font-weight: bold;
  }
}
.credit-active {
  background-color: $credit-active-background;
}

.manage-credit__register_modal {
  border-top: none !important;
  margin-top: 0 !important;
}
