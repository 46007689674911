/*/* ------------------------------------------------------------ *\
	Variables
\* ------------------------------------------------------------ */

$font-sans-serif: Arial, Helvetica, sans-serif;
$font-serif: Times, 'Times New Roman', serif;
$font-averta: 'Averta', sans-serif;
$font-averta-demo: 'Averta Demo', sans-serif;
$font-avenir: 'Avenir Next Condensed', sans-serif;
$font-sf-pro: 'SF Pro Text', sans-serif;
$font-national: 'National', sans-serif;
$font-material-icon: 'Material Icons';

$body-text: #111947;

$white: #fff;
$black: #000;
$dark-gray: #313840;
$light-gray: #939eaa;
$color-grey-dark: #777;
$color-title: #111947;
$green: #24cf5f;
$blue: #4baff0;
$red: #ff2c2c;
$pink: #ff5978;
$gray-boder: #e0e0e0;
$gray-text: #7f7f7f;
$border-new: #eaedf3;
$host-msg: #cff1dd;
$guest-msg: #eaedf3;
$border-input: #cccccc;
$border-input-focus: #2684ff;

$blue-main: #12c4c4;
$sub-color: #ff6c6c;

$icon-color: #9496ab;
$bg-color-1: #f9f9f9;
$bg-color-2: #f1f3f8;
$bg-color-3: #f5f5f5;

$blue-main-hover: #0e9d9d;
$gray-divider: #cccccc;
$color-text-normal: #727488;
$gray-new: #989ca0;
$background-header: #111947;
$deactive-status: #d80027;
$copy-right-background: #010a29;
$placeholder-input: #aaaaaa;

$badge-background-hover: #4b4b4b;
$button-hover: #0fa7a7;
$button-active: #41d0d0;
$button-sub-active: #ff8989;
$button-cencel-active: #e5e5e5;

$default-font-size: 1.6rem;
$default-font-size-second: 1.4rem;
$default-font-size-third: 1.2rem;

$font-size-base: 16px;
$line-height-base: 1.25;

$shell-max-width: 1156px;
$shell-gutter: 24px;

//DESKTOP FIRST
$responsive-xxs: 374px;
$responsive-xs: 767px;
$responsive-sm: 1023px;
$responsive-md: 1279px;
$responsive-lg: 1439px;

$mobile-small: '(max-width: #{$responsive-xxs}) ';
$mobile: '(max-width: #{$responsive-xs}) ';
$tablet: '(max-width: #{$responsive-sm}) ';
$tablet-portrait: '(max-width: #{$responsive-sm}) ';
$small-desktop: '(max-width: #{$responsive-md}) ';
$medium-desktop: '(max-width: #{$responsive-lg}) ';
$large-desktop: '(max-width: #{$responsive-lg}) ';
$retina: '(min-resolution: 2dppx) ';

//MOBILE FIRST

// X-Small devices (portrait phones, less than 576px)

// Small devices (landscape phones, 576px and up)

$responsive-small-phones: 320px;
$small-mobiles: '(min-width: #{$responsive-small-phones}) ';

$responsive-phones: 375px;
$mobiles: '(min-width: #{$responsive-phones}) ';

$responsive-large-phones: 576px;
$large-mobiles: '(min-width: #{$responsive-large-phones}) ';

// Medium devices (tablets, 768px and up)
$responsive-tablets: 768px;
$tablets: '(min-width: #{$responsive-tablets}) ';

// Large devices (desktops, 992px and up)
$responsive-small-desktops: 992px;
$small-desktops: '(min-width: #{$responsive-small-desktops}) ';

// X-Large devices (large desktops, 1200px and up)
$responsive-desktops: 1200px;
$desktops: '(min-width: #{$responsive-desktops}) ';

// XX-Large devices (larger desktops, 1400px and up)
$responsive-large-desktops: 1400px;
$large-desktops: '(min-width: #{$responsive-large-desktops}) ';

// variables common update 04/07/2021
$colorText: #111947; //color text headding
$colorTextError: #ff6c6c; //color text error
$colorTextContent: #32395a; //color text normal
$colorTextWarning: #efbe14; //color text normal

// font size title
$fontTitleLarge: 22px;
$fontTitleMedium: 20px;
$fontTitleFit: 18px;
$fontTitleSmall: 16px;

// font size text content
$fontContentLarge: 24px;
$fontContentMedium: 20px;
$fontContentFit: 16px;
$fontContentSmall: 14px;

// font size text modal
$fontModalLarge: 24px;
$fontModalBig: 22px;
$fontModalMedium: 20px;
$fontModalNomal: 18px;
$fontModalFit: 16px;
$fontModalSmall: 14px;

//font weight title
$fontWeigth: bold;
$fontWeigthNormal: normal;

//font weight text modal
$fontWeigthModalMedium: 500;

//line height title h
$lineHeightTitleVeryThick: 28px;
$lineHeightTitleThick: 26px;
$lineHeightTitleMedium: 24px;
$lineHeightTitleThin: 21px;

//line height text content
$lineHeightContentVeryThick: 32px;
$lineHeightContentThick: 28px;
$lineHeightContentMedium: 26px;
$lineHeightContentThin: 20px;

//line height text modal
$lineHeightModalVeryThick: 32px;
$lineHeightModalThick: 28px;
$lineHeightModalMedium: 26px;
$lineHeightModalThin: 20px;

@function vw($val1, $val2) {
  @return ($val1 / $val2) * 100vw;
}
