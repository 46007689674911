.withdraw__default-padding {
  flex-grow: 1;
  padding: 1rem 1.5rem 0 1.5rem;
}
.wrap-withdraw {
  display: flex;
  flex-direction: column;
  font-family: Roboto;

  p {
    margin: 0;
  }

  h3 {
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.578rem;
    color: #111947;
    @media #{$tablets} {
      font-size: 1.6rem;
      line-height: 2.8rem;
    }
  }
  &_content {
    @media #{$tablets} {
      max-width: 100vw;
    }
    &_button {
      display: flex;
      justify-content: center;
      align-items: center;
      .button-register {
        display: flex;
        position: relative;
        width: 50rem;
        @media #{$mobile} {
          flex-direction: column;
          align-items: center;
        }
        &--spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        .accept-button {
          background: $blue-main;
          color: $white;
        }
        .cancel-button {
          color: $body-text;
          box-sizing: border-box;
          border: 1px solid #ccc;
          margin-right: 2rem;
          @media #{$mobile} {
            margin: 0;
            margin-bottom: 1.5rem;
          }
        }
      }
    }
  }
  @media #{$tablet} {
    display: flex;
    justify-content: center;
    &_header {
      max-width: 100%;
      padding-right: 2rem;
    }
    &_content {
      max-width: 100%;
      .box-content {
        grid-template-columns: 1fr;
        justify-items: center;
        .card-wrap {
          margin-bottom: 2rem;
        }
      }
    }
    &_content-main {
      margin-right: 2rem;
    }
  }
}

@media #{$mobile} {
  .links {
    display: none !important;
  }
}
