.forgot-password-noti {
  box-sizing: border-box;
  &__container {
    text-align: center;
    margin-top: 8rem;
    margin-bottom: 10rem;
    @media #{$tablets} {
      margin-top: 16rem;
    }
    @media #{$desktops} {
      margin-top: 24rem;
      margin-bottom: 23.5rem;
    }
  }
  &__title {
    color: $color-title;
    font-size: 1.7rem;
    line-height: 2rem;
    font-weight: 700;
    padding-bottom: 0.6rem;
    @media #{$tablets} {
      font-size: 2.4rem;
      line-height: 2.813rem;
      padding-bottom: 1.2rem;
    }
  }
  &__content {
    max-width: 30rem;
    margin: 0 auto;
    @media #{$tablets} {
      max-width: 40rem;
    }
  }
  &__already-sent > span,
  &__not-received {
    font-size: 1.4rem;
    line-height: 1.95rem;
    color: $body-text;
  }
  &__already-sent {
    a {
      color: $blue-main;
    }
  }
  &__not-received {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    @media #{$tablets} {
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
  }
  &__group-buttons {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
      justify-content: center;
    }
  }
  &__try-again {
    color: $body-text;
    background-color: $gray-divider;
  }
  &__back-to-login {
    color: $white;
    background-color: $blue-main;
    margin-top: 1rem;
    @media #{$tablets} {
      margin-top: 0;
      margin-left: 1.5rem;
    }
  }
  .forgot-password-button {
    height: 5rem;
    width: 21.2rem;
    @media #{$tablets} {
      height: 5rem;
    }
  }
}
