@import './variables.scss';

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Roboto';
}
//H1に共通のCSS
.title-h1 {
  font-weight: $fontWeigth;
  line-height: 1.75;
  color: $colorText;
  font-size: calc( 22vw / 4.14);
  @media #{$tablets} {
	  font-size: calc( 22vw / 14);
  }
  @media #{$large-desktops} {
	  font-size: 2.2rem;
  }
}
//H2に共通のCSS
.title-h2 {
  font-weight: $fontWeigth;
  line-height: 1.75;
  color: $colorText;
  font-size: calc( 20vw / 4.14 );
  @media #{$tablets} {
	  font-size: calc( 20vw / 14);
  }
  @media #{$large-desktops} {
	  font-size: 2rem;
  }
}
//H2エラーに共通のCSS
.title-h2-error {
	@extend .title-h2;
  color: $colorTextError;
}
//H3に共通のCSS
.title-h3 {
  font-weight: $fontWeigth;
  line-height: 1.75;
  color: $colorText;
  font-size: calc( 18vw / 4.14 );
  @media #{$tablets} {
	  font-size: calc( 18vw / 14);
  }
  
  @media #{$desktops} {
	  font-size: 1.8rem;
  }
  
}
//H4に共通のCSS
.title-h4 {
  font-weight: $fontWeigth;
  font-size: calc( 16vw / 4.14 );
  line-height: 1.75;
  color: $colorText;
  @media #{$tablets} {
	  font-size: calc( 16vw / 14);
  }
  @media #{$large-desktops} {
	  font-size: 1.6rem;
  } 
}
