.noti-wrap{
	&_content{
		>div{
			padding: calc(20vw / 4.14 ) 0;
		}
		>div+div{
			border-top: 1px solid #ccc;
		}
		.no-notification {
			color: #111947;
			font-size: calc( 14vw / 4.14 );
			display: flex;
			justify-content: center;
			align-items: center;
			flex-direction: column;
			font-weight: bold;
			&:hover {
				background-color: transparent;
				cursor: none;
			}
		}
		&-noti{
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			.wrap-header{
				width: calc( 30vw / 4.14);
				.box-status{
					color: #ff6c6c;
					font-weight: 700;
					font-size: calc( 14vw / 4.14 );
					line-height: 1;
					white-space: nowrap;
				}
				.box_circle{
					.circle{
						width: calc( 14vw / 4.14 );
						height: calc( 14vw / 4.14 );
						background: #ff6c6c;
						border-radius: 50%;
					}
				}
			}
			.wrap-content{
				width: calc( 100% - calc( 30vw / 4.14));
				padding-left: calc( 10vw / 4.14 );
				box-sizing: border-box;
				.box_date{
					color: #32395A;
					font-size: calc( 14vw / 4.14 );
					line-height: 1;
					margin-bottom: calc( 10vw / 4.14);
				}
				.text_content{
					.noti_title{
						font-size: calc(16vw / 4.14 );
						font-weight: 700;
						color: #111947;
						line-height: 1.75;
						margin-bottom: calc( 5vw / 4.14 );
					}
					.noti_content{
						font-size: calc(16vw / 4.14 );
						color: #111947;
						line-height: 1.75;
						overflow: hidden;
						text-overflow: ellipsis;
						display: -webkit-box;
						-webkit-line-clamp: 2;
						-webkit-box-orient: vertical;
						word-break: break-all;
					}
				}
			}
		}
		
	}
	@media #{$tablets} {
		&_content{
			>div{
				cursor: pointer;
				padding: 0;
	      &:hover {
	        background-color: #f1f3f8;
	        transition: cubic-bezier(0.39, 0.575, 0.565, 1);
	      }
			}
			>div+div{
				border-top: none;
			}
			.no-notification {
				margin-top: calc( 30vw / 14 );
				font-size: calc( 14vw / 14 );
			}
			&-noti{
				.wrap-header{
					width: calc( 40vw / 14);
					.box-status{
						margin-top: calc( 30vw / 14 );
						font-size: calc( 14vw / 14 );
						white-space: nowrap;
					}
					.box_circle{
						margin-top: calc( 30vw / 14 );
						.circle{
							width: calc( 14vw / 14 );
							height: calc( 14vw / 14 );
						}
					}
					
				}
				.wrap-content{
					border-bottom: 1px solid #ccc;
					width: calc( 100% - calc( 40vw / 14));
					padding: calc( 30vw / 14 ) 0 calc( 30vw / 14 ) calc( 10vw / 14 );
					.box_date{
						font-size: calc( 14vw / 14 );
						margin-bottom: calc( 10vw / 14);
					}
					.text_content{
						.noti_title{
							font-size: calc(16vw / 14 );
							margin-bottom: calc( 5vw / 14 );
						}
						.noti_content{
							font-size: calc(16vw / 14 );
						}
					}
				}
			}
			
		}
	}
	@media #{$large-desktops} {
		&_content{
			>div+div{
				border-top: none;
			}
			.no-notification {
				font-size: 1.4rem;
				margin-top: 3rem;
			}
			&-noti{
				.wrap-header{
					width: 3em;
					.box-status{
						font-size: 1.4rem;
						margin-top: 3rem;
						white-space: nowrap;
					}
					.box_circle{
						margin-top: 3rem;
						.circle{
							width: 1.4rem;
							height: 1.4rem;
						}
					}
					
				}
				.wrap-content{
					width: calc( 100% - 3rem);
					padding-left:1rem;
					padding: 3rem 0 3rem 1rem;
					.box_date{
						font-size: 1.4rem;
						margin-bottom: 1rem;
					}
					.text_content{
						.noti_title{
							font-size: 1.6rem;
							margin-bottom: .5rem;
						}
						.noti_content{
							font-size: 1.6rem;
						}
					}
				}
			}		
		}
	}
}

