.top {
  $text-color: #313232;
  $gray-background: #f8f8f8;
  $link-hover: #6e6f6f;
  $link-color: #313232;
  &__container {
    scroll-behavior: smooth;
    box-sizing: border-box;
    color: $text-color;
    font-family: 'M PLUS 1p';
    position: relative;
    overflow: hidden;
    h2,
    h3,
    h4 {
      color: $text-color;
    }
    h2[class$='label'] {
      font-weight: 500;
      padding-top: calc(12vw / 4.14);
      text-align: center;
      @media #{$tablets} {
        padding-left: calc(18vw / 14);
        padding-top: 0;
      }
      @media #{$large-desktops} {
        padding-left: 1.8rem;
      }
    }
    h2[class$='label'] > span:first-child {
      font-family: Montserrat;
      text-transform: uppercase;
      font-size: calc(32vw / 4.14);
      line-height: 1.22;
      letter-spacing: 0.02em;
      padding-right: calc(2em / 32);
      @media #{$tablets} {
        font-size: calc(48vw / 14);
        line-height: 1.21;
      }
      @media #{$large-desktops} {
        font-size: 4.8rem;
        padding-right: 0.2rem;
      }
    }
    h2[class$='label'] > span:not(:first-child) {
      font-family: 'M PLUS 1p';
      font-size: calc(28vw / 4.14);
      line-height: 1.32;
      letter-spacing: 0.02em;
      @media #{$tablets} {
        font-size: calc(42vw / 14);
        line-height: 1.33;
      }
      @media #{$large-desktops} {
        font-size: 4.2rem;
      }
    }
    //responsive myPageButton
    .my-page-button {
      width: calc(220vw / 4.14);
      height: calc(43vw / 4.14);
      font-size: calc(16vw / 4.14);
      line-height: 1.05;
      border-radius: 1.5em;
      padding: 0.25em;
      @media #{$tablets} {
        font-size: calc(16vw / 14);
        font-weight: 500;
        width: calc(180vw / 14);
        height: calc(43vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.6rem;
        width: 18rem;
        height: 4.3rem;
      }
      &__represent {
        height: calc(35vw / 4.14);
        width: calc(35vw / 4.14);
        @media #{$tablets} {
          height: calc(35vw / 14);
          width: calc(35vw / 14);
        }
        @media #{$large-desktops} {
          height: 3.5rem;
          width: 3.5rem;
        }
      }
    }
  }
  &__fourDots-icon {
    svg {
      width: calc(25vw / 4.14);
      height: calc(25vw / 4.14);
      @media #{$tablets} {
        width: calc(30vw / 14);
        height: calc(30vw / 14);
      }
      @media #{$large-desktops} {
        width: 3rem;
        height: 3rem;
      }
    }
  }
  &__english-version {
    * {
      font-family: Montserrat !important;
    }
  }
  //header part
  &__header {
    position: relative;
    margin: 0 auto;
    width: 100%;
  }
  &__header-top--open {
    .header-top {
      position: fixed;
      top: 0;
      width: 100%;
      background: rgba(255,255,255,.8);
      padding-bottom: 2.5rem;
      animation: display-header 1s;
      @keyframes display-header {
        0% {
          opacity: 0;
        }
        100% {
          opacity: 1;
        }
      }
      &__register:hover {
        color: $white;
        background-color: $blue-main;
        svg {
          path {
            stroke: $white;
          }
        }
      }
      &__login:hover {
        background-color: #ff5858;
        border-color: #ff5858;
      }
      &__logo {
        cursor: pointer;
      }
    }
  }
  &__header-top--close {
    .header-top {
      position: fixed;
      width: 100%;
      top: -20rem;
      animation: hiding-header 1s;
      @keyframes hiding-header {
        0% {
          opacity: 1;
          top: 0;
        }
        99% {
          opacity: 0;
          top: 0;
        }
        100% {
          top: -20rem;
        }
      }
    }
  }
  &__player {
    height: 100%;
    width: 100%;
  }
  &__header-mobile {
    background-color: #f8f8f8;
    position: relative;
  }
  &__header-mobile-inner {
    position: relative;
    background-image: url(/assets/images/image/avatarium-machine.png);
    background-size: 100%;
    margin: 0 auto;
    width: calc(414vw / 4.14);
    height: calc(500vw / 4.14);
    text-align: center;
  }
  &__header-mobile-description {
    font-family: 'M PLUS 1p';
    font-size: calc(18vw / 4.14);
    letter-spacing: 0.01em;
    position: relative;
    padding-top: calc(69vw / 4.14);
    padding-bottom: calc(11vw / 4.14);
  }
  &__header-mobile-logo {
    position: relative;
    svg {
      width: calc(282vw / 4.14);
      height: calc(48vw / 4.14);
    }
  }
  &__header-mobile-playButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    svg {
      width: calc(110vw / 4.14);
      height: calc(80vw / 4.14);
    }
  }
  &__header-mobile-close {
    position: fixed;
    top: 1rem;
    right: 1rem;
    z-index: 11;
    svg {
      width: calc(24vw / 4.14);
      height: calc(24vw / 4.14);
    }
  }
  &__player-mobile {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: 10;
    background-color: #000;
  }

  &__button {
    font-size: calc(15vw / 4.14);
    width: calc(220em / 15);
    height: calc(48em / 15);
    outline: none;
    border: none;
    background-image: linear-gradient(to right, #1cd1c3 0%, #00b9d1 50%, #1cd1c3 50%, #00b9d1 100%);
    background-size: 200%;
    background-position: left;
    transition: background-position 0.3s ease-in-out;
    color: $white;
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    pointer-events: auto;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      width: calc(240em / 16);
      height: calc(58em / 16);
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
    }
    & > p {
      padding-left: calc(8em / 15);
      font-family: 'M PLUS 1p';
      line-height: 2.13;
      letter-spacing: 0.06em;
      font-weight: 500;
      @media #{$tablets} {
        padding-left: calc(8em / 16);
        line-height: 2;
      }
    }
    &:hover {
      background-position: right;
    }
    svg {
      width: calc(16vw / 4.14);
      height: calc(16vw / 4.14);
      @media #{$tablets} {
        width: calc(16vw / 14);
        height: calc(16vw / 14);
      }
      @media #{$large-desktops} {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
  }

  //navigation part
  &__navigation {
    position: fixed;
    bottom: calc(158vw / 4.14);
    left: calc(12vw / 4.14);
    padding: calc(8vw / 4.14) calc(6vw / 4.14);
    border-radius: calc(20vw / 4.14);
    background-color: $white;
    z-index: 5;
    animation: display-navigation 1s;
    a {
      color: $text-color;
    }
    @media #{$tablets} {
      bottom: auto;
      padding: 0;
      top: 50%;
      margin-top: calc(-55vw / 14);
      left: calc(60vw / 14);
      background-color: transparent;
      border-radius: 0;
    }
    @media #{$large-desktops} {
      margin-top: -5.5rem;
      left: 6rem;
    }
  }
  @keyframes display-navigation {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
  &__navigation-couple {
    display: flex;
    flex-direction: row;
    align-items: center;
    pointer-events: none;
    cursor: pointer;
    @media #{$tablets} {
      padding-bottom: calc(12vw / 14);
      height: calc(30vw / 14);
    }
    @media #{$large-desktops} {
      padding-bottom: 1.2rem;
      height: 3rem;
    }
    & p {
      display: none;
    }
    &:hover div {
      @media #{$tablets} {
        opacity: 1;
        height: calc(14vw / 14);
        width: calc(14vw / 14);
      }
      @media #{$large-desktops} {
        height: 1.4rem;
        width: 1.4rem;
      }
    }
    &:not(:last-child) {
      padding-bottom: calc(12vw / 4.14);
      @media #{$tablets} {
        padding-bottom: calc(12vw / 14);
      }
      @media #{$large-desktops} {
        padding-bottom: 1.2rem;
      }
    }
    @media #{$tablets} {
      pointer-events: visible;
      & p {
        display: block;
        color: transparent;
      }
      &:hover span {
        color: $text-color;
      }
    }
  }
  &__navigation-circle {
    height: calc(10vw / 4.14);
    width: calc(10vw / 4.14);
    border-radius: 50%;
    background-color: $blue-main;
    opacity: 0.3;
    @media #{$tablets} {
      height: calc(10vw / 14);
      width: calc(10vw / 14);
    }
    @media #{$large-desktops} {
      height: 1rem;
      width: 1rem;
    }
    &--active {
      opacity: 1;
    }
  }
  &__navigation-label {
    @media #{$tablets} {
      margin-left: calc(12vw / 14);
    }
    @media #{$large-desktops} {
      margin-left: 1.2rem;
    }
    span:first-child {
      @media #{$tablets} {
        font-family: Montserrat;
        font-size: calc(13vw / 14);
        font-weight: 500;
        letter-spacing: 0.02em;
        text-transform: uppercase;
      }
      @media #{$large-desktops} {
        font-size: 1.3rem;
      }
    }
    span:nth-child(2) {
      @media #{$tablets} {
        font-family: 'M PLUS 1p';
        font-size: calc(11vw / 14);
        font-weight: 500;
        letter-spacing: 0.02em;
        padding-left: calc(2vw / 14);
      }
      @media #{$large-desktops} {
        font-size: 1.1rem;
        padding-left: 0.2rem;
      }
    }
  }

  //download-part
  &__download {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 5;
    background-color: $white;
    border-top: 1px solid #3132321a;
    @media #{$tablets} {
      bottom: calc(3vw / 14);
      right: 0;
      width: auto;
      background-color: transparent;
      border-top: none;
    }
    @media #{$large-desktops} {
      bottom: 3rem;
    }
  }
  &__download-content {
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
      align-items: center;
      position: relative;
      padding-top: 1rem;
      padding-bottom: 1rem;
      transition: all 0.2s ease-in;
      right: calc(-290vw / 14);
      @media #{$large-desktops} {
        right: -26rem;
      }
      &:hover {
        @media #{$tablets} {
          right: calc(16vw / 14);
        }
        @media #{$large-desktops} {
          right: 1.6rem;
        }
      }
    }
  }
  &__download-content:hover &__download-badges {
    @media #{$tablets} {
      position: relative;
      visibility: visible;
      opacity: 1;
    }
    @media #{$large-desktops} {
    }
  }

  &__download-indicator {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding-top: calc(17vw / 4.14);
    padding-bottom: calc(33vw / 4.14);
    @media #{$tablets} {
      padding: 0;
    }
    @media #{$large-desktops} {
      padding-right: 0;
    }
    a:hover {
      text-decoration: none;
    }
    svg {
      width: calc(20vw / 4.14);
      height: calc(20vw / 4.14);
      @media #{$tablets} {
        width: calc(24vw / 14);
        height: calc(24vw / 14);
      }
      @media #{$large-desktops} {
        width: 2.4rem;
        height: 2.4rem;
      }
    }
  }
  &__download-text {
    font-family: Montserrat;
    font-size: calc(15vw / 4.14);
    letter-spacing: 0.02em;
    font-weight: 500;
    margin-bottom: 0;
    text-transform: uppercase;
    padding-left: calc(4vw / 4.14);
    cursor: pointer;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      padding-left: calc(4vw / 14);
      cursor: default;
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
      padding-left: 0.4rem;
    }
  }
  &__download-badges {
    padding-left: 30px;
    opacity: 0;
    visibility: hidden;
    transition: all 0.2s ease-in;
    svg:hover > g > path {
      fill: $badge-background-hover;
    }
    svg:hover > path:first-child {
      fill: $badge-background-hover;
    }
    @media #{$tablets} {
      & > :first-child {
        @media #{$tablets} {
          margin-right: calc(13vw / 14);
        }
        @media #{$large-desktops} {
          margin-right: 1.3rem;
        }
      }
      &:hover {
        display: block;
      }
    }
    svg {
      width: calc(135vw / 4.14);
      height: calc(40vw / 4.14);
      @media #{$tablets} {
        width: calc(135vw / 14);
        height: calc(40vw / 14);
      }
      @media #{$large-desktops} {
        width: 13.5rem;
        height: 4rem;
      }
    }
  }

  //content part
  &__content {
    position: relative;
    padding-top: calc(70vw / 4.14);
    padding-left: calc(24vw / 4.14);
    padding-right: calc(24vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(140vw / 14);
      padding-left: calc(160vw / 14);
      padding-right: calc(160vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 14rem;
      padding-left: 16rem;
      padding-right: 16rem;
      max-width: 140rem;
      margin: 0 auto;
    }
    h3 {
      font-size: calc(21vw / 4.14);
      font-weight: 500;
      line-height: 1.19;
      letter-spacing: 0.02em;
      font-family: 'M PLUS 1p';
      @media #{$tablets} {
        font-size: calc(35vw / 14);
        line-height: 1.2;
      }
      @media #{$large-desktops} {
        font-size: 3.5rem;
      }
    }
    a:hover {
      text-decoration: none;
    }
  }
  &__content-hero {
    position: relative;
    margin-bottom: calc(32vw / 4.14);
    @media #{$tablets} {
      margin-bottom: 0;
    }
    @media #{$large-desktops} {
      max-height: 50rem;
    }
    img {
      height: 100%;
      width: 100%;
      object-fit: contain;
      @media #{$tablets} {
        width: calc(650vw / 14);
        height: calc(500vw / 14);
      }
      @media #{$large-desktops} {
        width: 65rem;
        height: 50rem;
      }
    }
  }
  &__content-hero-wrapper {
    position: relative;
    max-height: 50rem;
  }
  &__content-text {
    font-size: calc(15vw / 4.14);
    margin-bottom: 0;
    padding-top: calc(18em / 15);
    padding-bottom: calc(18em / 15);
    line-height: 1.8;
    letter-spacing: 0.04em;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      padding-top: calc(36em / 16);
      padding-bottom: calc(36em / 16);
      line-height: 2;
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
      padding-top: 3.6rem;
      padding-bottom: 3.6rem;
    }
  }
  &__content-box1 {
    position: absolute;
    top: calc(166vw / 4.14);
    right: 0rem;
    z-index: 1;
    pointer-events: none;
    @media #{$tablets} {
      top: calc(85vw / 14);
    }
    @media #{$large-desktops} {
      top: 8.5rem;
    }
    svg {
      width: calc(90vw / 4.14);
      height: calc(180vw / 4.14);
      @media #{$tablets} {
        width: calc(429vw / 14);
        height: calc(360vw / 14);
      }
      @media #{$large-desktops} {
        width: 42.9rem;
        height: 36rem;
      }
    }
  }
  &__content-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: calc(40vw / 4.14);
    @media #{$tablets} {
      flex-direction: row;
      align-items: center;
      justify-content: center;
      padding-bottom: calc(80vw / 14);
    }
    @media #{$large-desktops} {
      padding-bottom: 8rem;
    }
  }
  &__content-first,
  &__content-third {
    @media #{$tablets} {
      display: flex;
      flex-direction: row;
    }
  }
  &__content-second {
    position: relative;
    @media #{$tablets} {
      display: flex;
      flex-direction: row-reverse;
    }
  }
  &__content-first-av {
    position: absolute;
    top: calc(-24vw / 4.14);
    left: calc(30vw / 4.14);
    pointer-events: none;
    @media #{$tablets} {
      top: calc(-50vw / 14);
      left: calc(42vw / 14);
    }
    @media #{$large-desktops} {
      top: -5rem;
      left: 4.2rem;
    }
    svg {
      width: calc(85vw / 4.14);
      height: calc(80vw / 4.14);
      @media #{$tablets} {
        width: calc(170vw / 14);
        height: calc(160vw / 14);
      }
      @media #{$large-desktops} {
        width: 17rem;
        height: 16rem;
      }
    }
  }
  &__content-first-quote {
    position: absolute;
    bottom: calc(-12vw / 4.14);
    pointer-events: none;
    @media #{$tablets} {
      bottom: calc(-28vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -2.8rem;
    }
    svg {
      width: calc(82vw / 4.14);
      height: calc(28vw / 4.14);
      @media #{$tablets} {
        width: calc(164vw / 14);
        height: calc(56vw / 14);
      }
      @media #{$large-desktops} {
        width: 16.4rem;
        height: 5.6rem;
      }
    }
  }
  &__content-first-information {
    padding-left: calc(20vw / 4.14);
    @media #{$tablets} {
      pointer-events: none;
      position: relative;
      padding-left: 0;
      margin-left: calc(-56vw / 14);
      margin-top: calc(168.2vw / 14);
      width: calc(430vw / 14);
    }

    @media #{$large-desktops} {
      margin-left: -5.6rem;
      margin-top: 16.82rem;
      width: 43rem;
    }
  }
  &__content-first-text {
    @media #{$tablets} {
      padding-top: calc(34vw / 14);
      padding-bottom: calc(35vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 3.4rem;
      padding-bottom: 3.5rem;
    }
  }
  &__content-second {
    position: relative;
    margin-top: calc(42vw / 4.14);
    @media #{$tablets} {
      margin-top: calc(140vw / 14);
    }
    @media #{$large-desktops} {
      margin-top: 14rem;
    }
  }
  &__content-second-at {
    position: absolute;
    top: calc(39.5vw / 4.14);
    right: calc(-24vw / 4.14);
    pointer-events: none;
    @media #{$tablets} {
      top: auto;
      right: calc(-75vw / 14);
      bottom: calc(-54vw / 14);
    }
    @media #{$large-desktops} {
      right: -7.5rem;
      bottom: -5.4rem;
    }
    svg {
      width: calc(75vw / 4.14);
      height: calc(85vw / 4.14);
      @media #{$tablets} {
        width: calc(176vw / 14);
        height: calc(170vw / 14);
      }
      @media #{$large-desktops} {
        width: 17.6rem;
        height: 17rem;
      }
    }
  }
  &__content-second-hero {
    position: relative;
    width: 100%;
  }
  &__content-second-quote {
    position: absolute;
    bottom: calc(-15vw / 4.14);
    right: 0;
    pointer-events: none;
    @media #{$tablets} {
      bottom: calc(-54vw / 14);
      left: calc(-45vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -5.4rem;
      left: -4.5rem;
    }
    svg {
      width: calc(120vw / 4.14);
      height: calc(28vw / 4.14);
      @media #{$tablets} {
        width: calc(240vw / 14);
        height: calc(58vw / 14);
      }
      @media #{$large-desktops} {
        width: 24rem;
        height: 5.8rem;
      }
    }
  }
  &__content-second-information {
    padding-left: calc(20vw / 4.14);
    @media #{$tablets} {
      position: relative;
      margin-right: calc(-54vw / 14);
      margin-top: calc(183vw / 14);
      padding-left: calc(54vw / 14);
      pointer-events: none;
    }
    @media #{$large-desktops} {
      margin-top: 18.3rem;
      margin-right: -5.4rem;
      padding-left: 5.4rem;
    }
  }
  &__content-second-text {
    @media #{$tablets} {
      width: calc(430vw / 14);
    }
    @media #{$large-desktops} {
      width: 43rem;
    }
  }

  &__content-second-dots {
    position: absolute;
    top: calc(-47vw / 4.14);
    left: 0;
    @media #{$tablets} {
      left: calc(-78vw / 14);
      top: calc(26vw / 14);
    }
    @media #{$large-desktops} {
      left: -7.8rem;
      top: 2.6rem;
    }
    svg {
      width: calc(10vw / 4.14);
      height: calc(69.5vw / 4.14);
      @media #{$tablets} {
        width: calc(20vw / 14);
        height: calc(139vw / 14);
      }
      @media #{$large-desktops} {
        width: 2rem;
        height: 13.9rem;
      }
    }
  }
  &__content-third {
    position: relative;
    padding-top: calc(40vw / 4.14);
    padding-bottom: calc(80vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(195vw / 14);
      padding-bottom: calc(180vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 19.5rem;
      padding-bottom: 18rem;
    }
  }
  &__content-third-lines {
    position: absolute;
    top: calc(34vw / 14);
    left: calc(-180vw / 14);
    @media #{$large-desktops} {
      top: 3.4rem;
      left: -18rem;
    }
  }
  &__content-third-dots {
    position: absolute;
    top: calc(-14.4vw / 4.14);
    right: 0;
    z-index: 1;
    @media #{$tablets} {
      top: calc(153vw / 14);
      right: calc(81vw / 14);
    }
    @media #{$large-desktops} {
      top: 15.3rem;
      right: 8.1rem;
    }
    svg {
      width: calc(35.5vw / 4.14);
      height: calc(52.4vw / 4.14);
      @media #{$tablets} {
        width: calc(71vw / 14);
        height: calc(105vw / 14);
      }
      @media #{$large-desktops} {
        width: 7.1rem;
        height: 10.5rem;
      }
    }
  }

  &__content-third-ar {
    position: absolute;
    left: calc(-24vw / 4.14);
    bottom: 0;
    pointer-events: none;
    @media #{$tablets} {
      left: calc(-56vw / 14);
      bottom: 0;
    }
    @media #{$large-desktops} {
      left: -5.6rem;
    }
    svg {
      width: calc(75vw / 4.14);
      height: calc(123.6vw / 4.14);
      @media #{$tablets} {
        width: calc(158vw / 14);
        height: calc(247vw / 14);
      }
      @media #{$large-desktops} {
        width: 15.8rem;
        height: 24.7rem;
      }
    }
  }
  &__content-third-quote {
    position: absolute;
    bottom: calc(-14vw / 4.14);
    right: 0;
    pointer-events: none;
    @media #{$tablets} {
      bottom: calc(-54vw / 14);
      right: calc(-74vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -5.4rem;
      right: -7.4rem;
    }
    svg {
      width: calc(120vw / 4.14);
      height: calc(28vw / 4.14);
      @media #{$tablets} {
        width: calc(240vw / 14);
        height: calc(56vw / 14);
      }
      @media #{$large-desktops} {
        width: 24rem;
        height: 5.6rem;
      }
    }
  }
  &__content-third-information {
    padding-left: calc(20vw / 4.14);
    z-index: 1;
    @media #{$tablets} {
      width: calc(430vw / 14);
      margin-top: calc(67vw / 14);
      padding-left: 0;
      margin-left: calc(-112vw / 14);
      margin-top: calc(134vw / 14);
      pointer-events: none;
    }
    @media #{$large-desktops} {
      width: 43rem;
      margin-top: 13.4rem;
      margin-left: -11.2rem;
    }
  }
  &__content-third-buttons {
    pointer-events: all;
    a:nth-child(2) {
      margin-left: calc(10vw / 4.14);
      @media #{$tablets} {
        margin-left: calc(10vw / 14);
      }
      @media #{$large-desktops} {
        margin-left: 1rem;
      }
    }
    svg:hover > g > path {
      fill: $badge-background-hover;
    }
    svg:hover > path:first-child {
      fill: $badge-background-hover;
    }
    svg {
      width: calc(135vw / 4.14);
      height: calc(40vw / 4.14);
      @media #{$tablets} {
        width: calc(135vw / 14);
        height: calc(40vw / 14);
      }
      @media #{$large-desktops} {
        width: 13.5rem;
        height: 4rem;
      }
    }
  }
  &__content-third-circle {
    position: absolute;
    bottom: calc(-70vw / 4.14);
    right: 0;
    z-index: 1;
    @media #{$tablets} {
      bottom: calc(-133.6vw / 14);
      right: calc(-80vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -13.36rem;
      right: -8rem;
    }
    svg {
      width: calc(183.5vw / 4.14);
      height: calc(164.5vw / 4.14);
      @media #{$tablets} {
        width: calc(367vw / 14);
        height: calc(329vw / 14);
      }
      @media #{$large-desktops} {
        width: 36.7rem;
        height: 32.9rem;
      }
    }
  }

  //spread part
  &__spread {
    position: relative;
    background-color: #f8f8f8;
    padding-top: calc(70vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(135vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 13.5rem;
    }
  }
  &__spread-inner {
    position: relative;
    padding: 0 calc(24vw / 4.14) calc(90vw / 4.14);
    @media #{$tablets} {
      padding: 0 calc(182vw / 14) calc(180vw / 14);
    }
    @media #{$large-desktops} {
      padding: 0 18.2rem 18rem;
      max-width: 140rem;
      margin: 0 auto;
    }
  }
  &__spread-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: calc(49.4vw / 4.14);
    @media #{$tablets} {
      flex-direction: row;
      padding-bottom: calc(80vw / 14);
      justify-content: center;
    }
    @media #{$large-desktops} {
      padding-bottom: 8rem;
    }
  }
  &__spread-label {
    span:nth-child(2) > span {
      display: block;
      text-align: center;
      @media #{$tablets} {
        display: inline;
        text-align: unset;
      }
    }
  }
  &__spread-content {
    padding-bottom: calc(48vw / 4.14);
    @media #{$tablets} {
      padding-bottom: calc(80vw / 14);
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: calc(50vw / 14);
    }
    @media #{$large-desktops} {
      gap: 5rem;
      padding-bottom: 8rem;
    }
  }
  &__spread-grid {
    display: flex;
    flex-direction: row;
    @media #{$tablets} {
      display: block;
    }
  }

  /******resolve mobile problems********/
  &__spread-grid:nth-child(2n) {
    flex-direction: row-reverse;
    margin-right: calc(-44vw / 4.14);
    @media #{$tablets} {
      margin-right: 0;
    }
    [class='top__spread-grid-info'] {
      margin-right: calc(-20vw / 4.14);
      @media #{$tablets} {
        margin-right: 0;
      }
    }
  }
  &__spread-grid:nth-child(2n + 1) {
    margin-left: calc(-44vw / 4.14);
    @media #{$tablets} {
      margin-left: 0;
    }
    [class='top__spread-grid-info'] {
      margin-left: calc(-20vw / 4.14);
      @media #{$tablets} {
        margin-left: 0;
      }
    }
  }
  &__spread-grid:not(:last-child) {
    padding-bottom: calc(60vw / 4.14);
    @media #{$tablets} {
      padding-bottom: 0;
    }
  }
  &__spread-grid:first-child &__spread-grid-label {
    margin-right: calc(-5vw / 4.14);
    @media #{$large-mobiles} {
      margin-right: 0;
    }
  }
  /*************************************/

  &__spread-grid-header {
    width: calc(200vw / 4.14);
    height: calc(200vw / 4.14);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    border-radius: 50%;
    box-shadow: 8px 8px 8px 0 #ededed, -8px -8px 8px 0 $white;
    padding-top: calc(44vw / 4.14);
    padding-bottom: calc(47vw / 4.14);
    @media #{$tablets} {
      height: calc(231vw / 14);
      width: 100%;
      border-radius: calc(80vw / 14);
      padding-top: calc(35vw / 14);
      padding-bottom: calc(42vw / 14);
    }
    @media #{$large-desktops} {
      height: 23.1rem;
      width: 31.2rem;
      border-radius: 8rem;
      padding-top: 3.5rem;
      padding-bottom: 4.2rem;
    }
    svg {
      width: calc(100vw / 4.14);
      height: calc(90vw / 4.14);
      @media #{$tablets} {
        width: calc(140vw / 14);
        height: calc(126vw / 14);
      }
      @media #{$large-desktops} {
        width: 14rem;
        height: 12.6rem;
      }
    }
  }
  &__spread-grid-title {
    font-family: Montserrat;
    font-size: calc(12vw / 4.14);
    text-transform: uppercase;
    padding-top: 0.03em;
    letter-spacing: 0.1em;
    @media #{$tablets} {
      font-size: calc(20vw / 14);
      letter-spacing: 0.04em;
    }
    @media #{$large-desktops} {
      font-size: 2rem;
    }
  }
  &__spread-grid-info {
    flex: 1 1;
    padding-top: calc(34vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(24vw / 14);
      padding-left: calc(16vw / 14);
      padding-right: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 2.4rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
    }
  }
  &__spread-grid-label {
    font-family: 'M Plus 1p';
    font-size: calc(16vw / 4.14);
    line-height: 1.63;
    letter-spacing: 0.02em;
    font-weight: 500;
    padding-bottom: calc(17vw / 4.14);
    @media #{$tablets} {
      font-size: calc(20vw / 14);
      line-height: 1.35;
      padding-bottom: calc(15vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 2rem;
      padding-bottom: 1.5rem;
    }
  }
  &__spread-grid-text {
    font-size: calc(13vw / 4.14);
    line-height: 2;
    letter-spacing: 0.04em;
    @media #{$tablets} {
      font-size: calc(14vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
    }
  }
  &__spread-services {
    font-size: calc(21vw / 4.14);
    padding-bottom: calc(23vw / 4.14);
    margin-bottom: 0;
    text-align: center;
    font-weight: 500;
    letter-spacing: 0.02em;
    @media #{$tablets} {
      font-size: calc(28vw / 14);
      padding-bottom: calc(29vw / 14);
      line-height: 1.32;
    }
    @media #{$large-desktops} {
      font-size: 2.8rem;
      padding-bottom: 2.9rem;
    }
  }
  &__spread-coming-soon {
    padding-top: calc(40vw / 4.14);
    padding-bottom: calc(40vw / 4.14);
    text-align: center;
    border-radius: 2rem;
    box-shadow: 8px 8px 8px 0 #ededed, -8px -8px 8px 0 $white;
    @media #{$tablets} {
      padding-top: calc(43vw / 14);
      padding-bottom: calc(43vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 4.3rem;
      padding-bottom: 4.3rem;
      max-width: 104rem;
      margin: 0 auto;
    }
  }
  &__spread-coming-soon-title {
    font-family: Montserrat;
    font-size: calc(22vw / 4.14);
    text-transform: uppercase;
    letter-spacing: 0.02em;
    color: $blue-main;
    margin-bottom: 0;
    padding-bottom: calc(6vw / 4.14);
    @media #{$tablets} {
      padding-bottom: calc(6vw / 14);
      font-size: calc(28vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 2.8rem;
      padding-bottom: 0.6rem;
    }
  }
  &__spread-coming-soon-date {
    font-size: calc(15vw / 4.14);
    font-weight: 500;
    letter-spacing: 0.02em;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      line-height: 1.44;
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
    }
  }
  &__spread-dots {
    position: absolute;
    bottom: calc(-22vw / 4.14);
    left: calc(24vw / 4.14);
    @media #{$tablets} {
      bottom: calc(-43vw / 14);
      left: calc(122vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -4.3rem;
      left: 12.2rem;
    }
    svg {
      width: calc(61vw / 4.14);
      height: calc(52.5vw / 4.14);
      @media #{$tablets} {
        width: calc(122vw / 14);
        height: calc(105vw / 14);
      }
      @media #{$large-desktops} {
        width: 12.2rem;
        height: 10.5rem;
      }
    }
  }

  //render customization
  &__customization {
    position: relative;
    padding-top: calc(60vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(140vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 14rem;
    }
  }
  &__customization-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: calc(42vw / 4.14);
    @media #{$tablets} {
      flex-direction: row;
      justify-content: center;
      padding-bottom: calc(62vw / 14);
    }
    @media #{$large-desktops} {
      padding-bottom: 6.2rem;
    }
  }
  &__customization-label {
    span:nth-child(2) > span {
      display: block;
      text-align: center;
      @media #{$tablets} {
        display: inline;
        text-align: unset;
      }
    }
  }
  &__customization-container {
    position: relative;
    padding: 0 0 calc(80vw / 4.14) calc(24vw / 4.14);
    @media #{$tablets} {
      padding: 0 0 calc(160vw / 14) calc(160vw / 14);
    }
    @media #{$large-desktops} {
      padding: 0 0 16rem 16rem;
      max-width: 140rem;
      margin: 0 auto;
    }
  }
  &__customization-decor {
    position: absolute;
    z-index: 1;
    top: calc(-44.5vw / 4.14);
    right: 0;
    @media #{$tablets} {
      top: calc(-85vw / 14);
      right: calc(90vw / 14);
    }
    @media #{$large-desktops} {
      top: -8.5rem;
      right: 9rem;
    }
    svg {
      width: calc(155.5vw / 4.14);
      height: calc(84.5vw / 4.14);
      @media #{$tablets} {
        width: calc(231vw / 14);
        height: calc(169vw / 14);
      }
      @media #{$large-desktops} {
        width: 23.1rem;
        height: 16.9rem;
      }
    }
  }
  &__customization-inner-container {
    position: relative;
    background-color: $gray-background;
    border-top-left-radius: 4rem;
    border-bottom-left-radius: 4rem;
    padding: calc(50vw / 4.14) calc(24vw / 4.14) calc(86vw / 4.14) 0;
    @media #{$tablets} {
      padding: calc(49vw / 14) calc(174vw / 14) 0 calc(216vw / 14);
      display: flex;
      flex-direction: row;
    }
    @media #{$large-desktops} {
      padding: 4.9rem 17.4rem 0 21.6rem;
    }
  }
  &__customization-display-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media #{$tablets} {
      display: block;
    }
  }
  &__customization-display {
    position: relative;
    border-radius: 2rem 2rem 0 0;
    box-shadow: 8px 0 8px 0 #ededed, -8px -8px 8px 0 $white;
    height: calc(432vw / 4.14);
    width: calc(239.3vw / 4.14);
    @media #{$tablets} {
      padding-bottom: 0;
      height: calc(530vw / 14);
      width: calc(292vw / 14);
    }
    @media #{$large-desktops} {
      height: 53rem;
      width: 29.2rem;
    }
    img {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
    }
    &--active {
      z-index: 1;
    }
  }

  &__customization-content {
    position: relative;
    padding-left: calc(37vw / 4.14);
    @media #{$tablets} {
      padding-top: calc(49vw / 14);
      padding-left: calc(126vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 4.9rem;
      padding-left: 12.6rem;
    }
  }
  &__customization-makeUp {
    position: absolute;
    background-color: $gray-background;
    bottom: calc(-32vw / 4.14);
    right: calc(-40vw / 4.14);
    width: calc(130vw / 4.14);
    height: calc(120vw / 4.14);
    text-align: center;
    padding-top: calc(24vw / 4.14);
    padding-bottom: calc(24vw / 4.14);
    border-radius: 2rem;
    z-index: 3;
    box-shadow: 8px 8px 8px 0 #ededed;
    @media #{$tablets} {
      position: static;
      width: calc(130vw / 14);
      height: calc(120vw / 14);
      padding-top: calc(24vw / 14);
      padding-bottom: calc(24vw / 14);
      box-shadow: 8px 8px 8px 0 #ededed, -8px -8px 8px 0 $white;
    }
    @media #{$large-desktops} {
      width: 13rem;
      height: 12rem;
      padding-top: 2.4rem;
      padding-bottom: 2.4rem;
    }
    svg {
      width: calc(80vw / 4.14);
      height: calc(72vw / 4.14);
      @media #{$tablets} {
        width: calc(80vw / 14);
        height: calc(72vw / 14);
      }
      @media #{$large-desktops} {
        width: 8rem;
        height: 7.2rem;
      }
    }
  }
  &__customization-bars {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: calc(322vw / 4.14);
    margin-top: calc(56vw / 4.14);
    margin-bottom: calc(32vw / 4.14);
    @media #{$tablets} {
      width: calc(430vw / 14);
      margin-top: calc(32vw / 14);
      margin-bottom: calc(40vw / 14);
    }
    @media #{$large-desktops} {
      width: 43rem;
      margin-top: 3.2rem;
      margin-bottom: 4rem;
    }
  }
  &__customization-bar {
    background-color: #12c4c433;
    height: 6px;
    width: calc((100% - 32px) / 5);
    //for user easier to click
    cursor: pointer;
    border-top: 2px solid #f8f8f8;
    border-bottom: 2px solid #f8f8f8;
    /////
    @media #{$tablets} {
      width: calc(80vw / 14);
    }
    @media #{$large-desktops} {
      width: 8rem;
    }
    &--active {
      background-color: $blue-main;
      opacity: 1;
      height: 2px;
      width: 100%;
      display: block;
      z-index: 2;
      animation: smooth-slide 3s;
    }
    @keyframes smooth-slide {
      0% {
        position: relative;
        left: 0;
        width: 0%;
      }
      100% {
        position: relative;
        left: 0;
        width: 100%;
      }
    }
  }
  &__customization-info-title {
    font-family: 'M PLUS 1p';
    font-size: calc(20vw / 4.14);
    font-weight: 500;
    padding-bottom: calc(16vw / 4.14);
    @media #{$tablets} {
      font-size: calc(28vw / 14);
      line-height: 1.32;
      letter-spacing: 0.02em;
      padding-bottom: calc(20vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 2.8rem;
      padding-bottom: 2rem;
    }
  }
  &__customization-info-text {
    font-size: calc(13vw / 4.14);
    line-height: 2;
    letter-spacing: 0.04em;
    @media #{$tablets} {
      font-size: calc(16vw / 14);
      max-width: 43.2rem;
    }
    @media #{$large-desktops} {
      font-size: 1.6rem;
    }
  }
  &__customization-dots {
    position: absolute;
    bottom: calc(-25vw / 4.14);
    right: calc(24vw / 4.14);
    @media #{$tablets} {
      bottom: calc(-30vw / 14);
      right: calc(110vw / 14);
    }
    @media #{$large-desktops} {
      bottom: -3rem;
      right: 11rem;
    }
    svg {
      width: calc(69.5vw / 4.14);
      width: calc(27vw / 4.14);
      @media #{$tablets} {
        width: calc(139vw / 14);
        height: calc(54vw / 14);
      }
      @media #{$large-desktops} {
        width: 13.9rem;
        height: 5.4rem;
      }
    }
  }
  &__customization-barCode {
    position: absolute;
    bottom: 0;
    left: calc(24vw / 4.14);
    @media #{$tablets} {
      left: calc(180vw / 14);
    }
    @media #{$large-desktops} {
      left: 18rem;
    }
    svg {
      width: calc(70vw / 4.14);
      height: calc(33vw / 4.14);
      @media #{$tablets} {
        width: calc(140vw / 14);
        height: calc(66vw / 14);
      }
      @media #{$large-desktops} {
        width: 14rem;
        height: 6.6rem;
      }
    }
  }

  //news part
  &__news {
    background-color: #323232;
    a:hover {
      text-decoration: none;
    }
  }
  &__news-inner {
    padding: calc(60vw / 4.14) calc(24vw / 4.14) calc(80vw / 4.14);
    @media #{$tablets} {
      padding: calc(120vw / 14) calc(160vw / 14);
    }
    @media #{$large-desktops} {
      padding: 12rem 16rem;
      max-width: 140rem;
      margin: 0 auto;
    }
  }
  &__news-title {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: $white;
    text-transform: uppercase;
    padding-bottom: calc(42vw / 4.14);
    @media #{$tablets} {
      flex-direction: row;
      padding-bottom: calc(60vw / 14);
      justify-content: center;
    }
    @media #{$large-desktops} {
      padding-bottom: 6rem;
    }
    h2 {
      font-family: Montserrat;
      font-size: calc(28vw / 4.14);
      font-weight: 500;
      letter-spacing: 0.02em;
      color: $white;
      padding-top: 1rem;
      padding-top: calc(10vw / 4.14);
      @media #{$tablets} {
        font-size: calc(36vw / 14);
        padding-top: 0;
        padding-left: calc(12vw / 14);
        line-height: 1.22;
      }
      @media #{$large-desktops} {
        font-size: 3.6rem;
        padding-left: 1.2rem;
      }
    }
  }
  &__news-content {
	  display: flex;
    column-gap: calc(40vw / 4.14);
    padding-bottom: calc(30vw / 4.14);
    margin-right: calc(-24vw / 4.14);
    color: $white;
    overflow-x: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    @media #{$tablets} {
	    display: grid;
	    grid-template-columns: repeat(3, minmax(0, 1fr));
      margin-right: 0;
      column-gap: calc(50vw / 14);
      overflow-x: visible;
      padding-bottom: 0;
    }
    @media #{$desktops} {
    }
    @media #{$large-desktops} {
      gap: 6rem;
      overflow-x: unset;
    }
    &::-webkit-scrollbar {
      display: none;
    }
    a {
      color: $white;
      @media #{$tablets} {
      }
    }
  }
  &__news-grid {
    h2 {
      color: $white;
    }
  }
  &__news-grid-image {
    width: calc(300vw / 4.14);
    height: calc(200vw / 4.14);
    background-color: #f5f5f5;
    border-radius: 2rem;
    @media #{$tablets} {
      width: calc(320vw / 14);
      height: calc(230vw / 14);
    }
    @media #{$large-desktops} {
      width: 32rem;
      height: 23rem;
    }
    img {
      border-radius: 2rem;
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  &__news-grid-title {
    font-family: 'M PLUS 1p';
    font-size: calc(18vw / 4.14);
    font-weight: 500;
    line-height: 1.51;
    letter-spacing: 0.04em;
    padding-top: calc(24vw / 4.14);
    margin-bottom: calc(16vw / 4.14);
    color: $white;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    @media #{$tablets} {
      font-size: calc(18vw / 14);
      padding-top: calc(24vw / 14);
      margin-bottom: calc(16vw / 14);
      line-height: 1.33;
      letter-spacing: 0.02em;
    }
    @media #{$large-desktops} {
      font-size: 1.8rem;
      padding-top: 2.4rem;
      margin-bottom: 1.6rem;
    }
  }
  &__news-grid-text {
    font-size: calc(13vw / 4.14);
    line-height: 2;
    letter-spacing: 0.04em;
    margin-bottom: calc(36vw / 4.14);
    //eclipse if the content's height is over 4 lines
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
    word-break: break-word;
    @media #{$tablets} {
      font-size: calc(14vw / 14);
      line-height: 1.5;
      margin-bottom: calc(16vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
    }
  }
  &__news-grid-date {
    font-size: calc(14vw / 4.14);
    line-height: 2;
    letter-spacing: 0.04em;
    @media #{$tablets} {
      font-size: calc(14vw / 14);
    }
    @media #{$large-desktops} {
      font-size: 1.4rem;
    }
  }
  &__news-button-area {
    color: red;
    padding-top: calc(36vw / 4.14);
    display: flex;
    flex-direction: row;
    justify-content: center;
    @media #{$tablets} {
      padding-top: calc(60vw / 14);
    }
    @media #{$large-desktops} {
      padding-top: 6rem;
    }
  }

  //footer part
  &__footer {
    //change color footer
    .footerC-wrap {
      margin-top: 0;
      background-color: $white;
      span {
        color: #000;
      }
      &__content--link {
        .text-link {
          color: #000 !important;
          &:hover {
            color: #000 !important;
          }
        }
      }
    }
  }
}
