.wrap-render_avatar-detail{
	margin: 0 auto;
	border-radius: calc( 6vw / 4.14 );
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
	position: relative;
  @media #{$tablets}{
		border-radius: calc( 6vw / 14 );
  }
  @media #{$large-desktops}{
		border-radius: .6rem;
  }
	
}


.control-avatar_content{
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	.wrap-render_avatar-detail{
		width: 100%;
		margin: 0 auto;
		border-radius: calc( 6vw / 4.14 );
	  background-repeat: no-repeat;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
		position: relative;
	}
  .wrap-icon {
    position: absolute;
    top: calc( 10vw / 4.14 );
    left: calc( 10vw / 4.14 );
  }
  .content2 {
    display: none;
  }
  
  @media #{$tablets}{
	  .wrap-render_avatar-detail{ border-radius: calc( 6vw / 14 ); }
	  .wrap-icon {
	    top: calc( 10vw / 14 );
	    left: calc( 10vw / 14 );
	  }
	  .wrap-content {
	    height: 100%;
	    width: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    font-weight: bold;
	    font-size: calc( 14vw / 14 );
	    line-height: 1.75;
	    border-radius: calc( 6vw / 14 );
	    word-break: break-word;
	    &:hover {
	      background: #06083c;
	      opacity: 0.8;
	      transition: all 0.25s ease-in;
		    .content2 {
		      display: block;
		      margin: 0 calc( 35vw / 14 );
		      width: 100%;
		      .date,
		      .avatar {
		        width: 100%;
		        display: flex;
		        border-bottom: 1px solid #6c6e99;
		        padding-bottom: calc( 5vw / 14 );
		        align-items: center;
		        svg{
			        width: calc( 16vw / 14 );
			        height: calc( 16vw / 14 );
			        vertical-align: middle;
		        }
		        
		      }
		      .avatar {
		        margin-top: calc( 25vw / 14 );
		      }
		      .text-date,
		      .text-avatar {
			      font-size: calc( 16vw / 14 );
		        color: #fff;
		        text-decoration: none;
		        margin-left: 1rem;
		      }
		    }
	      
	      
	    }
	  }
  }
  @media #{$large-desktops}{
	  .wrap-render_avatar-detail{ border-radius: .6rem; }
	  .wrap-icon {
	    top: 1rem;
	    left: 1rem;
	  }
	  .wrap-content {
	    font-size: 1.4rem;
	    border-radius: .6rem;
	    &:hover {
		    .content2 {
		      margin: 0 3.5rem;
		      .date,
		      .avatar {
		        padding-bottom: .5rem;
		        svg{
			        width: 1.6rem;
			        height: 1.6rem;
		        }
		        
		      }
		      .avatar {
		        margin-top: 2.5rem;
		      }
		      .text-date,
		      .text-avatar {
			      font-size: 1.6rem;
		      }
		    }
	      
	      
	    }
	  }
  }
}

.box-content2{
	a{
		text-decoration: none;
		&:hover{
			text-decoration: none;
		}
	}
	.wrap-render_avatar-detail{
		width: 100%;
		margin: 0 auto;
		border-radius: calc( 6vw / 4.14 );
	  background-repeat: no-repeat;
	  background-position: center;
	  background-repeat: no-repeat;
	  background-size: cover;
		position: relative;
	}
  .content2 {
    display: none;
  }
  @media #{$tablets}{
	  .wrap-render_avatar-detail{ border-radius: calc( 6vw / 14 ); }
	  .wrap-icon {
	    position: absolute;
	    top: calc( 10vw / 14 );
	    left: calc( 10vw / 14 );
	  }
	  .wrap-content {
	    height: 100%;
	    width: 100%;
	    display: flex;
	    justify-content: center;
	    align-items: center;
	    font-weight: bold;
	    font-size: calc( 12vw / 14 );
	    line-height: 1.75;
	    border-radius: calc( 6vw / 14 );
	    word-break: break-word;
	    &:hover {
	      background: #06083c;
	      opacity: 0.8;
	      transition: all 0.25s ease-in;
		    .content2 {
		      display: block;
		      margin: 0 calc( 10vw / 14 );
		      width: 100%;
		      .date,
		      .avatar {
		        width: 100%;
		        display: flex;
		        border-bottom: 1px solid #6c6e99;
		        padding-bottom: calc( 5vw / 14 );
		        align-items: center;
		        svg{
			        width: calc( 16vw / 14 );
			        height: calc( 16vw / 14 );
			        vertical-align: middle;
		        }
		        
		      }
		      .avatar {
		        margin-top: calc( 25vw / 14 );
		      }
		      .text-date,
		      .text-avatar {
			      font-size: calc( 12vw / 14 );
		        color: #fff;
		        text-decoration: none;
		        margin-left: 1rem;
		      }
		    }
	      
	      
	    }
	  }
  }
  @media #{$large-desktops}{
	  .wrap-render_avatar-detail{ border-radius: .6rem; }
	  .wrap-icon {
	    top: 1rem;
	    left: 1rem;
	  }
	  .wrap-content {
	    font-size: 1.2rem;
	    border-radius: .6rem;
	    &:hover {
		    .content2 {
		      margin: 0 0.5rem;
		      .date,
		      .avatar {
		        padding-bottom: .5rem;
		        svg{
			        width: 1.6rem;
			        height: 1.6rem;
		        }
		        
		      }
		      .avatar {
		        margin-top: 2.5rem;
		      }
		      .text-date,
		      .text-avatar {
			      font-size: 1.2rem;
		      }
		    }
	      
	      
	    }
	  }
  }
	
}






