.ReactModalPortal {
  position: relative;
  z-index: 400;
}

.booking {
  $border-input-modal: #9f9f9f;
  $background-slight-gray: #f1f3f9;
  $background-header: $color-text-normal;
  $brand-pink: $sub-color;
  &__header {
    border-bottom: 1px solid #ccc;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-bottom: 1rem;
    padding-top: 2rem;
    @media #{$tablets} {
      padding-top: 3.5rem;
    }
    &--title {
      display: inline-block;
      margin-left: 1.5rem;
      margin-top: 0;
      font-size: 2.2rem;
      line-height: 2.578rem;
      @media #{$tablets} {
        font-size: 2.4rem;
        line-height: 2.813rem;
      }
    }
  }
  &__describe {
    white-space: pre-line;
    color: $body-text;
    margin-bottom: calc(35vw / 4.14);
    p {
      font-size: calc(16vw / 4.14);
      line-height: 1.75;
    }
    p + p {
      margin-top: calc(10vw / 4.14);
    }
    @media #{$tablets} {
      margin-bottom: calc(35vw / 14);
      p {
        font-size: calc(16vw / 14);
      }
      p + p {
        margin-top: calc(5vw / 14);
      }
    }
    @media #{$large-desktops} {
      margin-bottom: 3.5rem;
      p {
        font-size: 1.6rem;
      }
      p + p {
        margin-top: 0.5rem;
      }
    }
  }
  &__options {
    display: flex;
    &--item {
      background-color: #f1f3f8;
      display: flex;
      justify-content: center;
      align-items: center;
      width: calc(172vw / 4.14);
      height: calc(50vw / 4.14);
      font-size: calc(16vw / 4.14);
      cursor: pointer;
      font-family: roboto;
      color: #111947;

      &.active {
        border-top: calc(6vw / 4.14) solid $blue-main;
        color: $color-title;
        background-color: #f9f9f9;
        font-weight: 700;
      }
    }
    @media #{$tablets} {
      &--item {
        width: calc(172vw / 14);
        height: calc(50vw / 14);
        font-size: calc(16vw / 14);
        &.active {
          border-top: calc(6vw / 14) solid $blue-main;
        }
      }
    }
    @media #{$large-desktops} {
      &--item {
        width: 17.2rem;
        height: 5rem;
        font-size: 1.6rem;
        &.active {
          border-top: 0.6rem solid $blue-main;
        }
      }
    }
  }
  &__history {
    &__inner-container {
      background-color: #f9f9f9;
      padding: calc(30vw / 4.14) calc(15vw / 4.14);
      @media #{$tablets} {
        padding: calc(30vw / 14) calc(30vw / 14);
      }
      @media #{$large-desktops} {
        padding: 3rem;
      }
    }
    &__lead {
      padding-bottom: calc(20vw / 4.14);
      margin-bottom: calc(20vw / 4.14);
      border-bottom: 1px solid #ccc;
      p {
        font-size: calc(16vw / 4.14);
        line-height: 1.75;
        color: $body-text;
      }
      p + p {
        margin-top: calc(20vw / 4.14);
      }
      @media #{$tablets} {
        padding-bottom: calc(20vw / 14);
        margin-bottom: calc(20vw / 14);
        p {
          font-size: calc(16vw / 14);
        }
        p + p {
          margin-top: calc(20vw / 14);
        }
      }
      @media #{$large-desktops} {
        padding-bottom: 2rem;
        margin-bottom: 2rem;
        p {
          font-size: 1.6rem;
        }
        p + p {
          margin-top: 2rem;
        }
      }
    }
    &__content {
    }
    &__wide-table {
      border-collapse: collapse;
      border-spacing: 0;
      margin: 0;
      width: 100%;
      box-sizing: border-box;
      border-radius: 0.5rem 0.5rem 0 0;
      border-left: 1px solid $gray-divider;
      border-right: 1px solid $gray-divider;
      border-bottom: 1px solid $gray-divider;
      tr {
        + tr {
          border-top: 1px solid $gray-divider;
        }
      }
      th {
        background-color: $background-header;
        color: $white;
        box-sizing: border-box;
        font-size: 1.4rem;
        line-height: 1.75;
        font-weight: 700;
        text-align: left;
        vertical-align: middle;
        padding: 1rem;
        + th {
          border-left: 1px solid $gray-divider;
        }
      }
      th:nth-child(1) {
        min-width: 12.2rem;
      }
      th:nth-child(2) {
        min-width: 18.7rem;
      }
      th:nth-child(3) {
        min-width: 20.7rem;
      }
      th:nth-child(4) {
        min-width: 32.2rem;
      }
      th:nth-child(5) {
        min-width: 14.3rem;
      }
      th:last-child {
        min-width: 5.7rem;
      }

      td {
        box-sizing: border-box;
        padding: 1rem 0.5rem;
        background-color: $white;
        color: $body-text;
        vertical-align: middle;
        word-break: break-word;
        + td {
          border-left: 1px solid $gray-divider;
        }
      }
      td:last-child {
        text-align: center;
      }
    }
    &__narrow-table {
      table {
        border-spacing: 0;
        width: 100%;
        overflow: hidden;
        border: 1px solid $gray-divider;
        border-collapse: separate;
        background: $white;
        border-radius: calc(5vw / 4.14);
      }
      table + table {
        margin-top: calc(15vw / 4.14);
      }
      tr:not(:last-child) > th,
      tr:not(:last-child) > td {
        border-bottom: 1px solid $gray-divider;
      }
      th,
      td {
        border: none;
        vertical-align: middle;
        word-break: break-word;
      }
      th {
        font-size: calc(14vw / 4.14);
        line-height: 1.75;
        font-weight: 700;
        color: $white;
        background-color: $color-text-normal;
        width: calc(150vw / 4.14);
        padding: calc(10vw / 4.14);
        text-align: left;
        box-sizing: border-box;
      }
      td {
        font-size: calc(14vw / 4.14);
        line-height: 1.75;
        color: $body-text;
        max-width: calc(100% - (150vw / 4.14));
        padding: calc(10vw / 4.14);
        box-sizing: border-box;
        text-align: left;
        position: relative;
        button {
          svg {
            vertical-align: bottom;
            width: calc(24vw / 4.14);
            height: auto;
          }
        }
      }
      .dropdown-close {
        tr:not(:first-child) {
          display: none;
        }
        tr:not(:last-child) > th,
        tr:not(:last-child) > td {
          border-width: 0;
        }
      }
      @media #{$tablets} {
        table {
          border-radius: calc(5vw / 14);
        }
        table + table {
          margin-top: calc(15vw / 14);
        }
        th {
          font-size: calc(14vw / 14);
          width: calc(150vw / 14);
          padding: calc(10vw / 14);
        }
        td {
          font-size: calc(14vw / 14);
          max-width: calc(100% - (150vw / 14));
          padding: calc(10vw / 14);
          button {
            svg {
              width: calc(24vw / 14);
              height: auto;
            }
          }
        }
      }
      @media #{$large-desktops} {
        table {
          border-radius: 0.5rem;
        }
        table + table {
          margin-top: 1.5rem;
        }
        th {
          font-size: 1.4rem;
          width: 15rem;
          padding: 1rem;
        }
        td {
          font-size: 1.4rem;
          max-width: calc(100% - (15rem));
          padding: 1rem;
          button {
            svg {
              width: 2.4rem;
              height: auto;
            }
          }
        }
      }
    }
    &__booking-tab {
      display: flex;
      flex-direction: row;
      align-items: center;
      svg {
        width: calc(24vw / 4.14);
        height: auto;
        @media #{$tablets} {
          width: calc(24vw / 14);
        }
        @media #{$large-desktops} {
          width: 2.4rem;
        }
      }
      span {
        font-weight: 500;
        color: $color-text-normal;
        font-size: calc(14vw / 4.14);
        line-height: 1.75;
        padding-left: calc(8vw / 4.14);
        @media #{$tablets} {
          font-size: calc(14vw / 14);
          padding-left: calc(8vw / 14);
        }
        @media #{$large-desktops} {
          font-size: 1.4rem;
          padding-left: 0.8rem;
        }
      }
    }
    &__dropdown-icon {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: calc(17vw / 4.14);
      svg {
        width: calc(14vw / 4.14);
        height: auto;
      }
      @media #{$tablets} {
        right: calc(17vw / 14);
        svg {
          width: calc(14vw / 14);
          height: auto;
        }
      }
    }
    &__active-text {
      color: $brand-pink !important;
      font-weight: 700;
      span {
        color: $brand-pink;
        font-weight: 700;
      }
    }
    &__trash-button {
      outline: none;
      border: 0;
      background-color: transparent;
      padding: 0;
      cursor: pointer;
    }
  }
  &__item {
    width: 100%;
    position: relative;
    font-size: $default-font-size;
    &:not(:last-child) {
      border-bottom: 1px solid $gray-divider;
    }
    padding: 3.1rem 0.7rem 1.5rem 1.5rem;
    @media #{$tablets} {
      padding: 1.1rem 4.5rem;
      display: flex;
    }
    &--delete {
      display: none;
      position: absolute;
      cursor: pointer;
      /* position the top  edge of the element at the middle of the parent */
      right: 5%; /* position the left edge of the element at the middle of the parent */
      @media #{$tablets} {
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
    &:hover {
      background-color: #f1f3f8;
      .booking__item--delete {
        display: block;
      }
    }
    &--header {
      display: flex;
      align-items: center;
      margin-bottom: 1rem;
      @media #{$tablets} {
        margin-bottom: 0;
        flex-direction: column;
        padding-right: 3.5rem;
        margin-right: 3.5rem;
        border-right: 1px solid $gray-divider;
      }
      &__label {
        padding: 0.5rem;
        border: 1px solid $gray-divider;
        border-radius: 3px;
        margin-left: 1rem;
        @media #{$tablets} {
          margin-top: 1rem;
          margin-left: 0;
        }
      }
    }
    &--content {
      &__date {
        font-size: 2rem;
        line-height: 2.344rem;
        color: $body-text;
        font-weight: 500;
        margin-bottom: 1.4rem;
      }
      &__label-wrapper {
        @media #{$tablets} {
          display: flex;
        }
      }
      &__label {
        color: $body-text;
        font-size: $default-font-size;
        line-height: 1.875rem;
        &:not(:last-child) {
          margin-bottom: 1rem;
          @media #{$tablets} {
            margin-right: 5rem;
          }
        }
        span {
          margin-left: 1rem;
          font-weight: 700;
          display: inline-block;
          color: $sub-color;
        }
      }
    }
  }
  &__form {
    background: #f9f9f9;
    padding: calc(20vw / 4.14) calc(15vw / 4.14) calc(30vw / 4.14);
    margin-bottom: calc(30vw / 4.14);
    @media #{$tablets} {
      padding: calc(20vw / 14) calc(30vw / 14) calc(30vw / 14);
      margin-bottom: calc(30vw / 14);
    }
    @media #{$large-desktops} {
      padding: 2rem 3rem 3rem;
      margin-bottom: 3rem;
    }

    &--explanation {
      padding-bottom: calc(20vw / 4.14);
      border-bottom: 1px solid $gray-divider;
      margin-bottom: calc(30vw / 4.14);
      p {
        color: $body-text;
        font-size: calc(16vw / 4.14);
        line-height: 1.75;
      }
      p + p {
        margin-top: calc(10vw / 4.14);
      }
      @media #{$tablets} {
        padding-bottom: calc(20vw / 14);
        margin-bottom: calc(30vw / 14);
        p {
          font-size: calc(16vw / 14);
        }
        p + p {
          margin-top: calc(10vw / 14);
        }
      }
      @media #{$large-desktops} {
        padding-bottom: 2rem;
        margin-bottom: 3rem;
        p {
          font-size: 1.6rem;
        }
        p + p {
          margin-top: 1rem;
        }
      }
    }
    &--body {
      @media #{$tablets} {
        position: relative;
      }
      &__wrapper {
        padding-bottom: calc(30vw / 4.14);
        border-bottom: 1px solid $gray-divider;
        margin-bottom: calc(30vw / 4.14);
        @media #{$tablets} {
          display: flex;
          justify-content: space-between;
          padding-bottom: calc(30vw / 14);
          margin-bottom: calc(30vw / 14);
        }
        @media #{$large-desktops} {
          padding-bottom: 3rem;
          margin-bottom: 3rem;
        }
        &--left {
          @media #{$tablets} {
            width: 48.5%;
          }
        }
      }

      &__describe {
        font-size: calc(16vw / 4.14);
        color: $body-text;
        margin-bottom: calc(25vw / 4.14);
        p {
          line-height: 1.75;
        }
        p + p {
          margin-top: calc(5vw / 4.14);
        }
        @media #{$tablets} {
          font-size: calc(16vw / 14);
          margin-bottom: calc(25vw / 14);
          p + p {
            margin-top: calc(5vw / 14);
          }
        }
        @media #{$large-desktops} {
          font-size: 1.6rem;
          margin-bottom: 2.5rem;
          p + p {
            margin-top: 0.5rem;
          }
        }
      }
      &__input {
        margin-bottom: calc(10vw / 4.14);
        font-size: calc(16vw / 4.14);
        width: 100%;
        @media #{$tablets} {
          margin-bottom: calc(10vw / 14);
          font-size: calc(16vw / 14);
        }
        @media #{$large-desktops} {
          margin-bottom: 1rem;
          font-size: 1.6rem;
        }
      }
      &__input2 {
        margin-bottom: calc(10vw / 4.14);
        font-size: calc(16vw / 4.14);
        width: 100%;
        @media #{$tablets} {
          margin-bottom: calc(10vw / 14);
          font-size: calc(16vw / 14);
        }
        @media #{$large-desktops} {
          margin-bottom: 1rem;
          font-size: 1.6rem;
        }
      }
      &__check {
        display: flex;

        .text-link {
          margin-top: calc(5vw / 4.14);
          display: inline-block;
          @media #{$tablets} {
            margin-top: calc(5vw / 14);
          }
          @media #{$large-desktops} {
            margin-top: 0.5rem;
          }
        }
      }
      &__map {
        width: 100%;
        overflow: hidden;
        &__content {
          width: 100%;
          height: calc(220vw / 4.14);
        }
        .text-link {
          margin-top: calc(15vw / 4.14);
          display: inline-block;
        }
        .form-field-error-message {
          margin-top: calc(10vw / 4.14);
          text-align: right;
          width: 100%;
          font-size: calc(12vw / 4.14);
        }
        @media #{$tablets} {
          width: 48.5%;
          margin-left: auto;
          &__content {
            width: 100%;
            height: calc(220vw / 14);
          }
          .text-link {
            margin-top: calc(15vw / 14);
            display: inline-block;
          }
          .form-field-error-message {
            margin-top: calc(10vw / 14);
            text-align: right;
            width: 100%;
            font-size: calc(12vw / 14);
          }
        }
        @media #{$large-desktops} {
          &__content {
            height: 22rem;
          }
          .text-link {
            margin-top: 1.5rem;
          }
          .form-field-error-message {
            margin-top: 1rem;
            font-size: 1.4rem;
          }
        }
      }
    }
    &--footer {
      &__describe {
        padding-bottom: calc(30vw / 4.14);
        p {
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          color: $body-text;
        }
        @media #{$tablets} {
          padding-bottom: calc(30vw / 14);
          p {
            font-size: calc(16vw / 14);
          }
        }
        @media #{$large-desktops} {
          padding-bottom: 3rem;
          p {
            font-size: 1.6rem;
          }
        }
      }
      &__images {
        margin-bottom: 2rem;
        @media #{$tablets} {
          margin-bottom: 3rem;
          display: flex;
          justify-content: space-between;
        }
        &--item {
          width: 100%;
          height: 16rem;
          border: 1px solid black;
          &:not(:last-child) {
            margin-bottom: 2rem;
          }
          @media #{$tablets} {
            width: 49%;
            height: 24rem;
            &:not(:last-child) {
              margin-bottom: 0;
            }
          }
        }
      }
      &__list-text {
        margin-bottom: 2rem;
        &--item {
          font-size: $default-font-size;
          line-height: 1.75;
          color: $body-text;
        }
      }
      &__board {
        width: 100%;
        color: $body-text;
        line-height: 1.75;
        padding-top: calc(20vw / 4.14);
        padding-bottom: calc(30vw / 4.14);
        border-bottom: calc(3vw / 4.14) dotted $gray-divider;
        font-size: calc(16vw / 4.14);
        @media #{$tablets} {
          padding-top: calc(20vw / 14);
          padding-bottom: calc(30vw / 14);
          border-bottom: calc(3vw / 14) dotted $gray-divider;
          font-size: calc(16vw / 14);
        }
        @media #{$large-desktops} {
          padding-top: 2rem;
          padding-bottom: 3rem;
          border-bottom: 0.3rem dotted $gray-divider;
          font-size: 1.6rem;
        }
      }
      &__link1 {
        color: $body-text;
        text-align: center;
        line-height: 1.75;
        padding-top: calc(30vw / 4.14);
        font-size: calc(16vw / 4.14);
        margin-bottom: calc(20vw / 4.14);
        @media #{$tablets} {
          padding-top: calc(30vw / 14);
          font-size: calc(16vw / 14);
          margin-bottom: calc(20vw / 14);
        }
        @media #{$large-desktops} {
          padding-top: 3rem;
          font-size: 1.6rem;
          margin-bottom: 2rem;
        }
      }
      &__checkbox {
        margin-bottom: calc(30vw / 4.14);
        &--checkbox {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        > p,
        > p > span {
          text-align: center;
        }
        &--input {
          -webkit-appearance: none !important;
        }
        &--input,
        &--input::before,
        &--input:checked::before {
          width: calc(18vw / 4.14) !important;
          height: calc(18vw / 4.14) !important;
        }
        &--input:checked::after {
          top: calc(6vw / 4.14) !important;
          left: calc(3vw / 4.14) !important;
          height: calc(6vw / 4.14) !important;
          width: calc(12vw / 4.14) !important;
        }
        &--content {
          color: $body-text;
          margin-left: calc(10vw / 4.14);
          font-size: calc(16vw / 4.14);
          line-height: 1.75;
          &_bold {
            font-weight: bold;
          }
        }
        @media #{$tablets} {
          margin-bottom: calc(30vw / 14);
          &--input,
          &--input::before,
          &--input:checked::before {
            width: calc(18vw / 14) !important;
            height: calc(18vw / 14) !important;
          }
          &--input:checked::after {
            top: calc(6vw / 14) !important;
            left: calc(3vw / 14) !important;
            height: calc(6vw / 14) !important;
            width: calc(12vw / 14) !important;
          }
          &--content {
            color: $body-text;
            margin-left: calc(10vw / 14);
            font-size: calc(16vw / 14);
          }
        }
        @media #{$large-desktops} {
          margin-bottom: 3rem;
          &--input,
          &--input::before,
          &--input:checked::before {
            width: 1.8rem !important;
            height: 1.8rem !important;
          }
          &--input:checked::after {
            top: 0.6rem !important;
            left: 0.3rem !important;
            height: 0.6rem !important;
            width: 1.2rem !important;
          }
          &--content {
            color: $body-text;
            margin-left: 1.4rem;
            font-size: 1.6rem;
          }
        }
      }
      &__clothes {
        &-header {
          margin-bottom: calc(20vw / 4.14);
          p {
            font-size: calc(16vw / 4.14);
            line-height: 1.75;
            color: $body-text;
          }
          @media #{$tablets} {
            margin-bottom: calc(20vw / 14);
            p {
              font-size: calc(16vw / 14);
            }
          }
          @media #{$large-desktops} {
            margin-bottom: 2rem;
            p {
              font-size: 1.6rem;
            }
          }
        }
        &-wrapContent {
          @media #{$tablets} {
            display: flex;
            justify-content: space-between;
          }
        }
        &-contentF {
          @media #{$tablets} {
            width: 100% !important;
            height: 8.7rem !important;
          }
        }
        &-content {
          background: #f1f3f8;
          border-radius: calc(6vw / 4.14);
          padding: calc(20vw / 4.14) calc(15vw / 4.14);
          box-sizing: border-box;
          + .booking__form--footer__clothes-content {
            margin-top: calc(20vw / 4.14);
          }
          @media #{$tablets} {
            width: 48.5%;
            border-radius: calc(6vw / 14);
            padding: calc(20vw / 14) calc(15vw / 14);
            &:nth-child(even) {
              margin-left: auto;
            }
            + .booking__form--footer__clothes-content {
              margin-top: 0;
            }
          }
          @media #{$large-desktops} {
            border-radius: 0.6rem;
            padding: 2rem 1.5rem;
          }

          &--box1 {
            &__header {
              text-align: center;
              p {
                font-size: calc(16vw / 4.14);
                line-height: 1.75;
                text-align: center;
              }

              @media #{$tablets} {
                p {
                  font-size: calc(16vw / 14);
                }
              }
              @media #{$large-desktops} {
                p {
                  font-size: 1.6rem;
                }
              }
            }
            &__content {
              padding-top: calc(15vw / 4.14);
              &-wrap1 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                .icon-group {
                  margin-bottom: calc(5vw / 4.14);
                  svg {
                    width: calc(145vw / 4.14);
                    height: auto;
                  }
                }
                .text-group {
                  font-size: calc(12vw / 4.14);
                  color: $body-text;
                }
              }
              &-wrap2 {
                @extend .booking__form--footer__clothes-content--box1__content-wrap1;
                margin-top: calc(20vw / 4.14);
                .icon-group {
                  svg {
                    width: calc(285vw / 4.14);
                    height: auto;
                  }
                }
              }
              @media #{$tablets} {
                display: flex;
                justify-content: center;
                padding-top: calc(15vw / 14);
                &-wrap1 {
                  .icon-group {
                    margin-bottom: calc(5vw / 14);
                    svg {
                      width: calc(145vw / 14);
                    }
                  }
                  .text-group {
                    font-size: calc(12vw / 14);
                  }
                }
                &-wrap2 {
                  margin-top: 0;
                  margin-left: calc(15vw / 4.14);
                  .icon-group {
                    svg {
                      width: calc(285vw / 14);
                      height: auto;
                    }
                  }
                }
              }
              @media #{$large-desktops} {
                padding-top: 1.5rem;
                &-wrap1 {
                  .icon-group {
                    margin-bottom: 0.5rem;
                    svg {
                      width: 14.5rem;
                    }
                  }
                  .text-group {
                    font-size: 1.2rem;
                  }
                }
                &-wrap2 {
                  margin-left: 1.5rem;
                  .icon-group {
                    svg {
                      width: 28.5rem;
                    }
                  }
                }
              }
            }
          }
          &--box2 {
            &__content {
              &-wrap1 {
                display: flex;
                align-items: center;
                width: 100%;
                padding-top: calc(15vw / 4.14);
                .icon-group {
                  width: calc(150vw / 4.14);
                  svg {
                    width: 100%;
                    height: auto;
                  }
                }
                .text-group__padding {
                  width: calc(100% - (150vw / 4.14));
                  text-align: center;
                  color: $body-text;
                  p {
                    font-size: calc(12vw / 4.14);
                    line-height: 1.75;
                  }
                }
              }
            }
            @media #{$tablets} {
              &__content {
                display: flex;
                justify-content: space-around;
                &-wrap1 {
                  flex-direction: column;
                  padding-top: calc(15vw / 14);
                  width: calc(150vw / 14);
                  .icon-group {
                    width: calc(150vw / 14);
                  }
                  .text-group__padding {
                    width: 100%;
                    p {
                      font-size: calc(12vw / 14);
                    }
                  }
                }
              }
            }
            @media #{$large-desktops} {
              &__content {
                &-wrap1 {
                  padding-top: 1.5rem;
                  width: 15rem;
                  .icon-group {
                    width: 15rem;
                  }
                  .text-group__padding {
                    p {
                      font-size: 1.2rem;
                    }
                  }
                }
              }
            }
          }
          &--box3 {
            &__content {
              padding-top: 1rem;
              padding-left: 2rem;
              padding-bottom: 2rem;
              position: relative;
              @media #{$tablets} {
                display: flex;
                padding-left: 0rem;
              }
              &-wrap1 {
                display: flex;
                .text-group {
                  font-size: 1.4rem;
                  line-height: 2.8rem;
                  color: $body-text;
                  padding-left: 1.5rem;
                }
              }
              &-icon {
                position: absolute;
                top: 0px;
                right: -15px;
                @media #{$tablets} {
                  display: none;
                }
              }
              &-iconDesktop {
                display: none;
                @media #{$tablets} {
                  display: block;
                  position: absolute;
                  top: -45px;
                  right: 455px;
                }
              }
            }
          }
        }
        &-detail {
          padding: calc(30vw / 4.14) 0 calc(30vw / 4.14);
          border-bottom: calc(3vw / 4.14) dotted $gray-divider;
          &--content {
            p {
              font-size: calc(16vw / 4.14);
              line-height: 1.75;
              color: $body-text;
            }
            p + p {
              margin-top: calc(10vw / 4.14);
            }
          }
          @media #{$tablets} {
            padding: calc(30vw / 14) 0 calc(30vw / 14);
            border-bottom: calc(3vw / 14) dotted $gray-divider;
            &--content {
              p {
                font-size: calc(16vw / 14);
              }
              p + p {
                margin-top: calc(10vw / 14);
              }
            }
          }
          @media #{$large-desktops} {
            padding: 3rem 0 3rem;
            border-bottom: 0.3rem dotted $gray-divider;
            &--content {
              p {
                font-size: 1.6rem;
              }
              p + p {
                margin-top: 1rem;
              }
            }
          }
        }
        &-warning {
          padding-top: calc(30vw / 4.14);
          &--header {
            margin-bottom: calc(15vw / 4.14);
            p {
              font-size: calc(16vw / 4.14);
              line-height: 1.75;
              color: $body-text;
            }
          }
          &--content {
            background: #f7f0f0;
            padding: calc(20vw / 4.14);
            border-radius: calc(6vw / 4.14);
            &__header {
              display: flex;
              justify-content: center;
              padding-top: 2rem;
              h3 {
                font-size: 1.6rem;
                line-height: 3.2rem;
                color: $body-text;
                font-weight: normal;
              }
            }
            &__container {
              display: grid;
              grid-template-columns: repeat(2, minmax(0, 1fr));
              grid-gap: 1.5rem;
              padding-top: 2rem;
              padding-left: 1.5rem;
              padding-right: 1.5rem;
              @media #{$tablets} {
                grid-template-columns: repeat(3, minmax(0, 1fr));
                width: 51rem;
              }
              .box1 {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                min-height: 12rem;
                background: #ffffff;
                border: 2px solid $sub-color;
                border-radius: 0.5rem;
                width: 100%;
                @media #{$tablets} {
                  height: 13rem;
                }
                p {
                  font-size: 1rem;
                  line-height: 1.5rem;
                  color: $body-text;
                  padding: 0 1rem;
                  text-align: center;
                  padding-top: 1rem;
                }
              }
            }
            &__next {
              padding: 1.5rem 0;
              display: flex;
              justify-content: center;
              @media #{$tablets} {
                display: none;
              }
            }
            &__nextDesktop {
              display: flex;
              justify-content: center;
              align-items: center;
              padding: 1.5rem 0;
              display: flex;
              @media #{$mobile} {
                display: none;
              }
            }
          }
          @media #{$tablets} {
            padding-top: calc(30vw / 14);
            &--header {
              margin-bottom: calc(15vw / 14);
              p {
                font-size: calc(16vw / 14);
              }
            }
          }
          @media #{$large-desktops} {
            padding-top: 3rem;
            &--header {
              margin-bottom: 1.5rem;
              p {
                font-size: 1.6rem;
              }
            }
          }
        }
        &-warning2 {
          &--content {
            background: #edfafa;
            border-radius: calc(6vw / 4.14);
            padding: calc(20vw / 4.14);
            &__header {
              display: flex;
              justify-content: center;
              margin-bottom: calc(25vw / 4.14);
            }
            &__container {
              &-box {
                width: 100%;
                background: #ffffff;
                border: calc(2vw / 4.14) solid $blue-main;
                border-radius: calc(6vw / 4.14);
                display: flex;
                flex-direction: column;
                position: relative;
                padding: calc(20vw / 4.14);
                &.is-error {
                  border-color: rgb(255, 108, 108);
                }
                .check-booking {
                  position: absolute;
                  top: calc(-10.5vw / 4.14);
                  left: calc(-10.5vw / 4.14);
                }
                .box1 {
                  display: flex;
                  flex-direction: row;
                  align-items: center;
                  > div:not(.box1-text) {
                    width: calc(72vw / 4.14);
                    svg {
                      width: 100%;
                      height: auto;
                    }
                  }
                }
                .box1 + .box1 {
                  margin-top: calc(20vw / 4.14);
                  padding-top: calc(20vw / 4.14);
                  border-top: calc(3vw / 4.14) dotted #ccc;
                }
                .box1-text {
                  width: calc(100% - (72vw / 4.14));
                  &__padding {
                    padding-left: calc(15vw / 4.14);
                    box-sizing: border-box;
                    font-size: calc(14vw / 4.14);
                    line-height: 1.75;
                    color: $body-text;
                    font-weight: 500;
                    .box-text_err {
                      color: $sub-color;
                    }
                  }
                }
              }
            }
            @media #{$tablets} {
              border-radius: calc(6vw / 14);
              padding: calc(20vw / 14);
              display: flex;
              justify-content: flex-start;
              align-items: flex-start;
              &__header {
                margin-bottom: calc(25vw / 14);
                &Container {
                  width: 48.5%;
                }
              }
              &__container {
                &-box {
                  border: calc(2vw / 14) solid $blue-main;
                  border-radius: calc(6vw / 14);
                  display: flex;
                  flex-direction: row;
                  padding: calc(20vw / 14) 0;
                  height: calc(200vw / 14);
                }
                .box1 {
                  flex-direction: column;
                  width: 50%;
                  > div:not(.box1-text) {
                    width: 100%;
                    margin-bottom: calc(5vw / 4.14);
                    text-align: center;
                    svg {
                      width: calc(72vw / 14);
                      height: auto;
                    }
                  }
                }
                .box1 + .box1 {
                  margin-top: 0;
                  padding-top: 0;
                  border-top: none;
                  border-left: calc(3vw / 14) dotted #ccc;
                }
                .check-booking {
                  position: absolute;
                  top: calc(-10.5vw / 14);
                  left: calc(-10.5vw / 14);
                }
                .box1-text {
                  width: 100%;
                  padding: 0 calc(15vw / 14);
                  box-sizing: border-box;
                  &__padding {
                    padding-left: 0;
                    text-align: center;
                    font-size: calc(12vw / 14);
                    font-weight: 600;
                  }
                }
              }
            }
            @media #{$large-desktops} {
              border-radius: 0.6rem;
              padding: 2rem;
              &__header {
                margin-bottom: 2.5rem;
              }
              &__container {
                &-box {
                  border: 0.2rem solid $blue-main;
                  border-radius: 0.6rem;
                  display: flex;
                  flex-direction: row;
                  padding: 2rem 0;
                  height: 18rem;
                }
                .box1 {
                  flex-direction: column;
                  width: calc(50% - 0.15rem);
                  > div:not(.box1-text) {
                    margin-bottom: 0.5rem;
                    svg {
                      width: 7.2rem;
                      height: auto;
                    }
                  }
                }
                .box1 + .box1 {
                  margin-top: 0;
                  padding-top: 0;
                  border-top: none;
                  border-left: 0.3rem dotted #ccc;
                }
                .check-booking {
                  top: -1.5rem;
                  left: -1.5rem;
                }
                .box1-text {
                  padding: 0 1.5rem;
                  &__padding {
                    font-size: 1.2rem;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  &__delete-modal-booking {
    &__container {
      color: $body-text;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: block;
      background-color: #fff;
      border-radius: 1rem;
      outline: none;
      width: 100vw;
      padding: 25px 30px 59px;
      @media #{$mobiles} {
        width: 92vw;
      }
      @media #{$mobile} {
        padding: 25px 15px 30px !important;
      }
      @media #{$tablets} {
        width: 60rem;
      }
    }
    &__header {
      text-align: center;
      @media #{$mobile} {
        padding-top: 25px;
      }
    }
    &__title {
      margin-bottom: 0;
      font-size: 2rem;
      font-weight: 700;
      color: $body-text;
      line-height: 1.5;
      @media #{$tablets} {
        font-size: 2.4rem;
      }
    }
    &__close {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      @media #{$tablets} {
        top: 2.4rem;
        right: 3rem;
      }
    }
    &__content {
      padding-top: 15px;
      text-align: center;

      @media #{tablets} {
        padding-top: 3rem;
      }
    }
    &__confirm-info {
      padding-bottom: 15px;
      border-bottom: 1px solid $gray-divider;
      font-weight: 500;
      p {
        margin-bottom: 0;
        @media #{$mobile} {
          font-size: $fontModalFit;
        }
      }
    }
    &__send-noti {
      p {
      }
    }
    &__resolved-buttons {
      display: flex;
      flex-direction: row;
      justify-content: center;
      > button {
        @media #{$tablets} {
          width: 240rem;
        }
        @media #{$mobile} {
          width: 150px !important;
          height: 50px !important;
        }
      }
      > button:last-child {
        margin-left: 1.5rem;
      }
    }
    &__yes {
      margin-left: 1.5rem;
    }
    &__yes-button {
      color: $white;
      background-color: $blue-main;
    }
  }
  &__modal-booking {
    position: absolute;
    left: 50%;
    top: 50%;
    display: block;
    width: 100vw;
    background-color: #fff;
    border-radius: 1rem;
    transform: translate(-50%, -50%);
    outline: none;
    max-height: calc(100% - 20px);
    overflow: auto;
    @media screen and(max-width:512px) {
      width: 92vw;
    }
    @media #{$large-mobiles} {
      width: 92vw;
    }
    @media #{$tablets} {
      width: 60rem;
    }
    &--notify {
      &.isBooking {
        .detail-avatar__delete-modal {
          &_header {
            &-text {
              max-width: 100%;
              border: none;
            }
          }
          &_content {
            padding-top: 0;
          }
        }
      }
      .detail-avatar__delete-modal {
        &_header {
          padding-bottom: 0;
          &-text {
            border-bottom: 1px solid #ccc;
            padding-bottom: 15px;
          }
        }
        &_content {
          border-top: none;
        }
      }
      @media #{$tablets} {
        padding: 2.5rem 3rem 3rem 3rem;
      }
    }
    @media #{$mobile-small} {
      height: calc(100% - 6rem);
      overflow-y: scroll;
    }

    &__header {
      text-align: center;
      border-bottom: 1px solid $gray-divider;
    }
    &__close {
      position: absolute;
      top: 2rem;
      right: 1.5rem;
      @media #{$tablets} {
        top: 2.4rem;
        right: 3rem;
      }
    }
    &__content {
      padding-top: 3rem;
      @media #{$tablets} {
        padding: 3rem 2rem 0 2rem;
        margin-left: 0;
        margin-right: 0;
      }
    }
    &__select-id {
      padding-bottom: 3rem;
      .input {
        display: block;
        &__label {
          margin-bottom: 1rem;
        }
      }
      div[class$='control'] {
        width: 100%;
        font-size: 1.4rem;
      }
      div[class$='menu'] {
        z-index: 20;
      }
    }
    &__select-id-label {
      padding-bottom: 0.8rem;
    }
    &__select-date {
      position: relative;
      padding-bottom: 3rem;
      .field {
        width: 100%;
      }
      .input {
        width: 100%;
        input {
          width: 100%;
          font-family: Roboto;
          color: $body-text;
          border-radius: 0.5rem;
          padding-left: 1.2rem;
          border-color: $border-input-modal;
          padding-top: 1.3rem;
          padding-bottom: 1.3rem;
          &::placeholder {
            color: #aaaaaa;
          }
        }
        i:not(.icon) {
          display: none;
        }
      }
      .clndr-calendars-segment {
        width: 80%;
        @media #{$large-mobiles} {
          width: 60%;
        }
        @media #{$tablets} {
          width: 50%;
        }
      }
    }
    &__select-date-label {
      padding-bottom: 0.8rem;
    }
    &__select-date-icon {
      position: absolute;
      z-index: 10;
      right: 1.5rem;
      top: 4rem;
    }

    &__select-hour {
      padding-bottom: 3rem;
      @media #{$tablets} {
        padding-bottom: 2rem;
      }

      @media #{$tablets} {
        div[class$='control'] {
          width: 50%;
          border-color: $border-input-modal;
        }
        div[class$='menu'] {
          width: 50%;
        }
      }
    }
    &__select-hour-label {
      padding-bottom: 0.9rem;
      display: inline-block;
    }
    &__select-time-label {
      padding-bottom: 1rem;
      display: inline-block;
    }
    &__not-display {
      visibility: hidden;
      opacity: 0;
    }
    &__select-time-button {
      position: relative;
      font-size: 1.4rem;
      line-height: 1.875rem;
      height: 3.5rem;
      width: 12rem;
      border: 1px solid $border-input-modal;
      border-radius: 0.5rem;
      color: $color-text-normal;
      outline: none;
      cursor: pointer;
      background-color: $white;
      @media #{$mobiles} {
        height: 4rem;
        width: 14.8rem;
      }
      &:hover {
        transform: translateY(-1px);
      }
      &:last-child {
        grid-column: 2 / 3;
        @media #{$tablets} {
          grid-column: 3 / 4;
        }
      }
      &:nth-last-child(2) {
        @media #{$tablets} {
          grid-column: 2 / 3;
        }
      }
    }
    &__select-time-check-icon {
      position: absolute;
      top: -0.8rem;
      right: -0.8rem;
    }
    &__select-time-area {
      display: grid;
      grid-column-gap: 0rem;
      align-content: end;
      justify-items: center;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      grid-gap: 1rem;
      @media #{$tablets} {
        grid-gap: 1.9rem;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
      }
    }
    &__reservation {
      padding-top: 3rem;
      text-align: center;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      &--spinner {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      > button {
        @media #{$mobiles} {
          width: 24rem;
        }
      }
    }
    &__reservation-button {
      width: 24rem;
      background-color: $blue-main;
      color: $white;
    }
  }
}

.booking__form--footer__clothes-warning2--content__headerContainer
  + .booking__form--footer__clothes-warning2--content__headerContainer {
  margin-top: calc(30vw / 4.14);
  @media #{$tablets} {
    margin-top: 0;
  }
}

.booking__form--footer__clothes-warning2--content__headerContainer
  + .booking__form--footer__clothes-warning2--content__headerContainer {
  @media #{$tablets} {
    margin-left: auto;
  }
}

.active-booking {
  background: rgba(18, 196, 196, 0.05);
  border: 1px solid $blue-main;
  color: $body-text;
}

.hidden {
  display: none;
}

.clndr-cell-today {
  background-color: $blue-main !important;
  color: #fff !important;
}

.clndr-cell:hover {
  background-color: #23a0a0 !important;
  color: #fff !important;
}
.content-form-booking {
  width: 100%;
  white-space: normal;
  > p {
    &:nth-child(2) {
      padding-bottom: 15px;
      border-bottom: 1px solid #ccc;
      font-weight: 500;
      word-break: break-word;
    }
  }
}
.margin-noti-left {
  margin-right: 20px;
  @media only screen and  #{$medium-desktop} {
    margin-right: 0px;
  }
}
