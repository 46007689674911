.card-member {
  width: 100%;
  box-sizing: border-box;
  border-radius: calc( 8vw / 4.14 );
  overflow: hidden;
  border: 1px solid #ccc;
  &__dropdown {
    position: absolute;
    top: 50%;
    right: calc( 20vw / 4.14 );
    cursor: pointer;
    transform: translateY(-50%);
    svg{
	    width: calc( 18vw / 4.14 );
	    height: calc( 10vw / 4.14 );
    }
  }
  &__is-close {
    @media #{$tablet} {
      & > div + div {
        max-height: 0;
        border-color: transparent;
        padding: 0 calc( 20vw / 4.14 );;
      }
    }
  }
  &_header {
    position: relative;
    color: #fff;
    width: 100%;
    background-color: #111947;
    padding: calc( 20vw / 4.14 ) calc( 20vw /4.14 );
    @include transition;
    .header_title {
      padding-bottom: 0;
      .title1 {
        font-weight: bold;
        font-size: calc( 24vw / 4.14 );
        line-height: 28px;
        letter-spacing: calc( .3vw / 4.14 );
        color: $blue-main;
        margin-right: calc( 8vw / 4.14 );
      }
      .title2 {
        color: #a5adbb;
        font-size: calc( 20vw / 4.14 );
        line-height: 2.8rem;
      }
    }
    .header_content {
      h3 {
        line-height: 2.813rem;
        padding-top: 0;
        padding-bottom: 0;
      }
      .card {
        font-size: calc( 24vw / 4.14 );
        padding-bottom: 1rem;
        font-weight: bold;
        line-height: 2.8rem;
        padding-top: 2.5rem;
        font-weight: 700;
      }
      .content {
        font-size: calc( 16vw / 4.14 );
        line-height: 1.75;
        p:not(:first-child) {
          @media #{$tablet} {
            display: none;
          }
        }
      }
    }
  }
  &_wrapper-content {
    height: auto;
    overflow: hidden;
    transition: 0.35s ease-in-out;
    padding: 0 calc( 20vw / 4.14 ) calc( 20vw / 4.14 );
  }
  &_content {
    display: flex;
    align-items: center;
    padding-top: 2rem;
    svg{
	    width: calc( 16vw / 4.14 );
	    height: calc( 14vw / 4.14 );
    }
    span {
      margin-left: calc( 8vw / 4.14 );
      font-size: calc( 16vw / 4.14 );
      line-height: 19px;
      color: $body-text;
    }
  }
  &_button {
    display: flex;
    justify-content: center;
    margin-top: calc( 30vw / 4.14 ); 
    button.button-custom {
      &.outline-1,
      &.outline-2 {
        cursor: auto;
        &:hover,
        &:active {
          background: #fff;
        }
      }
    }
    &+p{
	    font-size: calc( 14vw / 4.14 );
	    line-height: 2;
    }
  }
  
  @media #{$tablets}{
	  border-radius: calc( 8vw / 14 );
	  &__dropdown {
		  display: none;
	    right: calc( 20vw / 14 );
	    svg{
		    width: calc( 18vw / 14 );
		    height: calc( 10vw / 14 );
	    }
	  }
	  &_header {
	    padding: calc( 20vw / 14 ) calc( 20vw /14 );
	    .header_title {
	      .title1 {
	        font-size: calc( 24vw / 14 );
	        letter-spacing: calc( .3vw / 14 );
	        margin-right: calc( 8vw / 14 );
	      }
	      .title2 {
	        font-size: calc( 20vw / 14 );
	      }
	    }
	    .header_content {
	      .card {
	        font-size: calc( 24vw / 14 );
	      }
	      .content {
	        font-size: calc( 16vw / 14 );
	      }
	    }
	  }
	  &_wrapper-content {
	    padding: 0 calc( 20vw / 14 ) calc( 20vw / 14 );
	  }
	  &_content {
	    svg{
		    width: calc( 16vw / 14 );
		    height: calc( 14vw / 14 );
	    }
	    span {
	      margin-left: calc( 8vw / 14 );
	      font-size: calc( 16vw / 14 );
	    }
	  }
	  &_button {
	    margin-top: calc( 30vw / 14 );
	    &+p{
		    font-size: calc( 14vw / 14 );
	    }
	  }
  }
  @media #{$desktops}{
	  border-radius: .8rem;
	  &_header {
	    padding: 3rem 2rem 2rem;
	    .header_title {
	      .title1 {
	        font-size: 2.4rem;
	        letter-spacing: .03rem;
	        margin-right: .8rem;
	      }
	      .title2 {
	        font-size: 2rem;
	      }
	    }
	    .header_content {
	      .card {
	        font-size: 2.4rem;
	      }
	      .content {
	        font-size: 1.6rem;
	      }
	    }
	  }
	  &_wrapper-content {
	    padding: 0 2rem 2rem;
	  }
	  &_content {
	    svg{
		    width: 1.6rem;
		    height: 1.4rem;
	    }
	    span {
	      margin-left: .8rem;
	      font-size: 1.6rem;
	    }
	  }
	  &_button {
	    margin-top: 3rem; 
	    &+p{
		    font-size: 1.4rem;
	    }
	  }
  }
}

.card-member + .card-member {
	margin-top: calc( 20vw / 4.14 );
	@media #{$tablets}{
		margin-top: 0;
	}
}
