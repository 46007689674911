.detail-news {
  &__container {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
  }
  &__primary {
    width: 100%;
  }
  &__header {
    border-bottom: 1px solid #ccc;
    padding-bottom: calc(10vw / 4.14);
    .title-h1 {
      margin-bottom: calc(15vw / 4.14);
      line-height: (36/24);
      word-break: break-word;
    }
  }
  &__date {
    font-size: calc(14vw / 4.14);
    font-family: 'Roboto';
    font-weight: 400;
    color: #727488;
  }
  &__body {
    color: $body-text;
    margin-top: calc(20vw / 4.14);
    h2 {
      font-size: calc(18vw / 4.14);
      line-height: 1.5;
      margin-bottom: calc(10vw / 4.14);
    }
    > p {
      font-size: calc(16vw / 4.14);
      line-height: 1.75;
      margin-bottom: calc(30vw / 4.14);
    }
    figure {
      text-align: center;
      margin-bottom: calc(15vw / 4.14);
      img {
        max-width: 100%;
        height: auto;
      }
      figcaption {
        font-size: calc(12vw / 4.14);
        padding: calc(5vw / 4.14);
        line-height: 1.5;
        color: #727488;
      }
    }
    ul {
      list-style-type: none;
      margin-bottom: calc(30vw / 4.14);
      li {
        font-size: calc(16vw / 4.14);
        padding-left: 1em;
        line-height: 1.75;
        position: relative;
        &:before {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          content: '・';
        }
      }
      li + li {
        margin-top: calc(5vw / 4.14);
      }
    }
    ol {
      list-style-type: none;
      margin-bottom: calc(30vw / 4.14);
      li {
        font-size: calc(16vw / 4.14);
        padding-left: 1.5em;
        line-height: 1.75;
        position: relative;
        counter-increment: list;
        &:before {
          display: block;
          position: absolute;
          left: 0;
          top: 0;
          content: counter(list) '.';
        }
      }
      li + li {
        margin-top: calc(5vw / 4.14);
      }
    }
    table {
      float: none;
      width: 100%;
      border-collapse: collapse;
      border-spacing: 0;
      border: 1px solid #ccc;
      margin-bottom: calc(30vw / 4.14);
      thead {
        tr {
          border-bottom: 1px solid #ccc;
          th {
            background: #dfe0e6;
            padding: calc(10vw / 4.14) 0;
            text-align: center;
            font-size: calc(12vw / 4.14);
          }
          th + th {
            border-left: 1px solid #ccc;
          }
        }
      }
      tbody {
        tr {
          th {
            background: #dfe0e6;
            padding: calc(10vw / 4.14);
            width: 30%;
            border-right: 1px solid #ccc;
            text-align: center;
            font-size: calc(12vw / 4.14);
            vertical-align: middle;
          }
          td {
            padding: calc(10vw / 4.14);
            font-size: calc(12vw / 4.14);
            line-height: 1.2;
            vertical-align: middle;
          }
          td + td {
            border-left: 1px solid #ccc;
          }
        }
        tr + tr {
          border-top: 1px solid #ccc;
        }
      }
    }
    a {
      @include text-link();
    }
  }
  &__foot {
    clear: both;
    margin-top: calc(80vw / 4.14);
    button {
      width: 100%;
      margin: 0 auto;
      display: block;
    }
  }
  &__secondary {
    margin-top: calc(60vw / 4.14);
    width: 100%;
  }
  &__item {
    margin-bottom: calc(20vw / 4.14);
    padding: 0 0 calc(20vw / 4.14);
    border-bottom: 1px solid #ccc;
    > div {
      display: block;
      color: $body-text;
      text-decoration: none;
      > div {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .text-link {
          text-align: right;
          display: block;
        }
      }
    }
  }
  &__other-article-date {
    margin-bottom: calc(10vw / 4.14);
    font-size: calc(12vw / 4.14);
    font-family: 'Roboto';
    font-weight: 400;
    color: #727488;
  }
  &__thumb {
    width: calc(120vw / 4.14);
    img {
      width: 100%;
      height: calc(120vw / 4.14);
      vertical-align: bottom;
      object-fit: cover;
      border-radius: calc(5vw / 4.14);
    }
  }
  &__content {
    width: calc(100% - calc(120vw / 4.14));
    padding-left: calc(15vw / 4.14);
    box-sizing: border-box;
  }
  &__ttle {
    font-size: calc(16vw / 4.14);
    line-height: (22/16);
    font-weight: 700;
    word-break: break-all;
    margin-bottom: calc(35vw / 4.14);
    word-break: break-word;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .breadcrumb {
    width: calc((730 / 1100) * 100%);
    display: flex;
    align-items: center;
    span {
      &:last-child {
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        display: inline-flex;
        > div {
          width: 100%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
  @media #{$tablets} {
    .breadcrumb {
      width: 73rem;
    }
    &__container {
      flex-direction: row;
      margin-bottom: calc(60vw / 14);
    }
    &__primary {
      width: calc((730 / 1100) * 100%);
    }
    &__header {
      padding-bottom: calc(10vw / 14);
      .title-h1 {
        margin-bottom: calc(15vw / 14);
      }
    }
    &__date {
      font-size: calc(14vw / 14);
    }
    &__body {
      margin-top: calc(20vw / 14);
      h2 {
        font-size: calc(18vw / 14);
        margin-bottom: calc(10vw / 14);
      }
      > p {
        font-size: calc(16vw / 14);
        margin-bottom: calc(30vw / 14);
      }
      figure {
        margin-bottom: calc(15vw / 14);
        figcaption {
          font-size: calc(12vw / 14);
          padding: calc(5vw / 14);
        }
      }
      ul {
        margin-bottom: calc(30vw / 14);
        li {
          font-size: calc(16vw / 14);
        }
        li + li {
          margin-top: 0.5rem;
        }
      }
      ol {
        margin-bottom: calc(30vw / 14);
        li {
          font-size: calc(16vw / 14);
        }
        li + li {
          margin-top: 0.5rem;
        }
      }
      table {
        margin-bottom: calc(30vw / 14);
        thead {
          tr {
            th {
              padding: calc(10vw / 14) 0;
              font-size: calc(12vw / 14);
            }
          }
        }
        tbody {
          tr {
            th {
              padding: calc(10vw / 14);
              font-size: calc(12vw / 14);
            }
            td {
              padding: calc(10vw / 14);
              font-size: calc(12vw / 14);
            }
          }
        }
      }
    }
    &__foot {
      margin-top: calc(80vw / 14);
      button {
        width: calc(270vw / 14);
      }
    }
    &__secondary {
      margin-left: auto;
      width: calc((350 / 1100) * 100%);
      margin-top: 0;
    }
    &__item {
      margin-bottom: calc(20vw / 14);
      padding: 0 0 calc(20vw / 14);
    }
    &__other-article-date {
      margin-bottom: calc(10vw / 14);
      font-size: calc(12vw / 14);
    }
    &__thumb {
      width: calc(120vw / 14);
      img {
        height: calc(120vw / 14);
        border-radius: calc(5vw / 14);
      }
    }
    &__content {
      width: calc(100% - calc(120vw / 14));
      padding-left: calc(15vw / 14);
    }
    &__ttle {
      font-size: calc(16vw / 14);
      margin-bottom: calc(35vw / 14);
    }
  }

  @media #{$large-desktops} {
    &__container {
      flex-direction: row;
      margin-bottom: 6rem;
    }
    &__primary {
      width: 73rem;
    }
    &__header {
      padding-bottom: 1rem;
      .title-h1 {
        margin-bottom: 1.5rem;
      }
    }
    &__date {
      font-size: 1.4rem;
    }
    &__body {
      margin-top: 2rem;
      h2 {
        font-size: 1.8rem;
        margin-bottom: 1rem;
      }
      > p {
        font-size: 1.6rem;
        margin-bottom: 3rem;
      }
      figure {
        margin-bottom: 1.5rem;
        figcaption {
          font-size: 1.2rem;
          padding: 0.5rem;
        }
      }
      ul {
        margin-bottom: 3rem;
        li {
          font-size: 1.6rem;
        }
        li + li {
          margin-top: 0.5rem;
        }
      }
      ol {
        margin-bottom: 3rem;
        li {
          font-size: 1.6rem;
        }
        li + li {
          margin-top: 0.5rem;
        }
      }
      table {
        margin-bottom: 3rem;
        thead {
          tr {
            th {
              padding: 1rem 0;
              font-size: 1.4rem;
            }
          }
        }
        tbody {
          tr {
            th {
              padding: 1rem;
              font-size: 1.4rem;
              line-height: 1.75;
            }
            td {
              padding: 1rem;
              font-size: 1.4rem;
              line-height: 1.75;
            }
          }
        }
      }
    }
    &__foot {
      margin-top: 8rem;
      button {
        width: 27rem;
      }
    }
    &__item {
      margin-bottom: 2rem;
      padding: 0 0 2rem;
    }
    &__other-article-date {
      margin-bottom: 1rem;
      font-size: 1.2rem;
    }
    &__thumb {
      width: 12rem;
      img {
        height: 12rem;
        border-radius: 0.5rem;
      }
    }
    &__content {
      width: calc(100% - 12rem);
      padding-left: 1.5rem;
    }
    &__ttle {
      font-size: 1.6rem;
      margin-bottom: 3.5rem;
    }
  }
}
