.title {
  font-size: $default-font-size;
  font-weight: 700;
  color: $color-title;
}

.text-normal {
  font-size: $default-font-size;
  font-weight: 400;
  color: $color-grey-dark;
  line-height: 1.875rem;
}

.text-normal-second {
  font-size: $default-font-size-second;
  font-weight: 700;
  color: #111947;
  font-size: 1.6rem;
  line-height: 3.2rem;
}

.text-normal-third {
  font-size: $default-font-size-third;
  font-weight: 400;
  color: $color-grey-dark;
  line-height: 2.2rem;
}

.text-pre-line {
  white-space: pre-line;
}

.text-right {
  text-align: right;
}

.text-link {
  @include text-link();
}

.text-link-signup {
  @include text-link();
  color: rgb(255, 108, 108);
  &:hover {
    color: rgb(255, 108, 108);
  }
}

.font-weight-bold {
  font-weight: bold;
}

.title-header {
  font-size: 2.4rem;
  font-weight: 700;
  color: $color-title;
  line-height: 2.813rem;
  @media #{$mobile} {
    font-size: 2.2rem;
  }
}

.text-center {
  text-align: center;
}
