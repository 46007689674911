@font-face {
  font-family: 'IBMPlexMono-Regular';
  src: url('../../../assets/fonts/IBMPlexMono-Regular.ttf');
  font-style: normal;
}

@font-face {
  font-family: 'MADEEvolveSans';
  src: url('../../../assets/fonts/MADEEvolveSansMediumEVO.otf');
  font-weight: 500;
  font-style: normal;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;500;700&display=swap');
//Montserrat font
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500&display=swap');
//Mplus1p
@import url('https://fonts.googleapis.com/css2?family=M+PLUS+1p:wght@400;500&display=swap');
