.add-creadit {
  display: flex;
  flex-direction: column;
  &_header {
    &-title {
      h3 {
        font-weight: bold;
        font-size: 2rem;
        line-height: 2.3rem;
        color: $body-text;
        padding-top: 3.5rem;
        margin-bottom: 1rem;
        @media #{$mobile} {
          padding-top: 1rem;
        }
      }
    }
  }
  &_content {
    display: flex;
    @media #{$tablet} {
      flex-direction: column;
      max-width: 100vw;
      align-items: center;
    }
    &-img {
      max-width: 35rem;
      height: 48rem;
      margin-right: 2.7rem;
      @media #{$tablet} {
        margin-right: 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 1rem;
      }
      img {
        border-radius: 1rem;
      }
    }
    &-box {
      width: 73rem;
      @media #{$mobile} {
        max-width: 100%;
      }
      .box-creadit {
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $body-text;
        &-title {
          margin-top: 3rem;
          font-weight: bold;
          margin-bottom: 0.8rem;
          margin-left: 2rem;
          &_content {
            margin-left: 2rem;
          }
        }

        &__send {
          display: flex;
          position: relative;
          align-items: center;
          justify-content: flex-end;
          @media #{$mobile} {
            justify-content: center;
          }
          margin-top: 2rem;
          &--spinner {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }

          button {
            width: 25.5rem;
            height: 5rem;
            @media #{$mobiles} {
              width: 25.5rem;
              height: 5rem;
            }
            @media #{$large-mobiles} {
              width: 25.5rem;
              height: 5rem;
            }
          }
        }
        .box-creadit_visa {
          margin: 2rem 0;
          display: grid;
          grid-template-columns: repeat(2, minmax(0, 1fr));
          grid-gap: 1rem;
          @media #{$tablet} {
            grid-template-columns: 1fr;
            margin: 3 0;
          }
          .visa {
            padding-right: 2rem;
          }
          .activeVisa {
            background-color: rgb(240, 255, 255);
          }
          .box-creadit_visa1 {
            display: flex;
            align-items: center;
            padding: 1.3rem 1.4rem;
            .visa {
              margin-left: 1.6rem;
              flex: 1;
            }
            .visa-card {
              .visa-show {
                display: none;
                @media #{$mobile} {
                  display: inline-block;
                }
              }
              .visa-number {
                padding-right: 1.4rem;
                @media #{$mobile} {
                  display: none;
                }
              }
            }
            .new-visa_icon {
              color: $color-text-normal;
            }
          }
          .box-new-visa {
            background: #f7f7f7;
          }
        }
      }
    }
    &-table {
      background-color: #f7f7f7;
      padding: 2rem;
      border-radius: 1rem;
      height: 28.5rem;
      @media #{$mobile} {
        max-width: 100vw;
        margin-top: 3rem;
        height: auto;
      }
      .table-title {
        font-weight: bold;
        font-size: 1.6rem;
        line-height: 1.9rem;
        color: $body-text;
        margin-bottom: 2rem;
      }
      .box-table {
        &__avatar-table {
          display: flex;
          flex-direction: column;
          border-radius: 1rem;
          @media #{$tablets} {
            display: flex;
            flex-direction: row;
          }
          &__group {
            display: flex;
            flex-direction: row;
            border: 1px solid #ccc;
            height: 4rem;
            @media #{$tablets} {
              flex-direction: column;
              width: 100%;
              flex: 1;
              height: auto;
              &:not(:first-child) {
                border-left: none;
              }
            }
          }
          &__group:first-child {
            @media #{$tablets} {
            }
          }
          &__group:last-child {
            @media #{$tablets} {
              flex: 2;
            }
          }
          &__group-th {
            display: flex;
            flex-direction: row;
            align-items: center;
            color: $white;
            padding-left: 1.5rem;
            background-color: $color-text-normal;
            width: 50%;
            @media #{$tablets} {
              width: auto;
              height: 4rem;
              padding-left: 0;
              justify-content: center;
            }
          }
          &__group-td {
            color: $body-text;
            padding-left: 1.5rem;
            display: flex;
            flex-direction: row;
            align-items: center;
            @media #{$tablets} {
              height: 4rem;
              justify-content: center;
              padding-left: 0;
            }
          }
          & > * + * {
            @media #{$tablet} {
              border-top: none;
            }
          }
        }
      }
      .box-select {
        display: flex;
        justify-content: space-between;
        margin-top: 1.5rem;
        align-items: center;
        padding-bottom: 3rem;
        border-bottom: 1px solid #ccc;
        @media #{$mobile} {
          align-items: flex-start;
          flex-direction: column;
          margin-top: 3rem;
        }
        &_title {
          h3 {
            font-weight: bold;
            font-size: 1.6rem;
            line-height: 2.2rem;
            color: $body-text;
            @media #{$mobile} {
              align-items: flex-start;
              flex-direction: column;
              margin-bottom: 1.5rem;
            }
          }
        }
        &-input {
          width: 34rem;
          @media #{$mobile} {
            max-width: 100%;
          }
        }
      }
      .box-price {
        display: flex;
        justify-content: flex-end;
        margin-top: 2rem;
        align-items: center;
        &_title {
          font-weight: bold;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: $body-text;
        }
        &_number {
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 2.8rem;
          color: $sub-color;
          margin-left: 2.6rem;
        }
      }
    }
  }
}
