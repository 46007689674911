.wrap-render_avatar{
    position: relative;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-radius: 1rem;
    @media#{$mobile} {
       border-radius: 0.6rem;
    }
 }
 