.re-download {
  padding-top: 100px;
  &__container {
    text-align: center;
    margin-bottom: calc(30vw / 4.14);
    @media #{$tablets} {
      margin-bottom: calc(30vw / 9.92);
    }
    @media #{$small-desktops} {
      margin-bottom: 3rem;
    }

    #h1 {
      @media screen and (max-width: 767px) {
        width: 292px;
      }
    }
  }
  &__describe-content-outline {
    text-align: center;
    color: $body-text;
    line-height: 1.75;
    font-size: calc(16vw / 4.14);
    @media #{$tablets} {
      font-size: calc(16vw / 14);
    }
    @media #{$small-desktops} {
      font-size: 1.6rem;
    }
  }
  &__describe-content {
    line-height: 1.75;
    font-weight: 400;
  }
}
