.switch-languages-top {
  [class$='singleValue'] {
    padding: 0;
    & > span {
      height: 100%;
      padding: 0;
      padding-left: 6px;
      display: flex;
      flex-direction: row;
      align-items: center;
    }
    & > span > span {
      padding-right: 0px;
    }
  }
  [class$='MenuList'] {
    & > div {
      padding: 4px;
    }
  }
  [class$='indicatorContainer'] {
    padding-left: 0;
  }
  [class$='option'] &__name {
    margin-left: 1.1rem;
  }
  &__group {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 0.6rem;
    & > svg {
      width: 3.3rem;
      height: 3.3rem;
    }
  }
  &__name {
    margin-left: 0.8rem;
  }
  &__japan {
    font-family: 'M PLUS 1p';
  }
  &__us {
    font-family: Montserrat;
  }
}
