@import "../components/common/basic_style_v2.scss";

/*--------------------
// エクスポート画面
--------------------*/
.control-export{
  .export-wrap_box{
    .title_head{
      @include sp_vw(20px, padding-bottom);
      @include sp_vw(30px, margin-bottom);
      border-bottom: 1px solid #ccc;
      h2{
        @include sp_vw(22px, font-size);
			  @include sp_vw(0px, margin-bottom);
			  @include sp_vw(20px, padding-left);
      }
      .service_name{
        display: flex;
			  align-items: center;
			  flex-basis: 100%;
        .image_wrap{
          width: 100px;
          height: 100px;
          img{
            width: 100%;
            height: auto;
            border: 1px solid #eee;
          }
        }
      }
      .input_wrap{
        margin-top: 2rem;
        .token__form{
          display: block;
          .input{
            display: block;
            input {
              width: 100%;
              @include sp_vw(50px, height);
            }
          }
        }
      }
      .btn_wrap{
        @include sp_vw(250px, width);
        margin: 0 auto;
        @include sp_vw(10px, margin-top);
      }
    }
    .section{
      @include sp_vw(20px, margin-bottom);
    }
    .text-link{
      font-size: 1.6rem;
      font-family: Roboto;
      font-weight: 400;
    }
  }

  // タブレット 縮小
  @media (min-width: $sp_w){
    .export-wrap_box{
      .title_head{
        @include vw(20px, $pc_w, padding-bottom);
			  @include vw(30px, $pc_w, margin-bottom);
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
          @include vw(22px, $pc_w, font-size);
				  @include vw(0px, $pc_w, margin-bottom);
				  @include vw(20px, $pc_w, padding-left);
				  @include vw(20px, $pc_w, padding-right);
        }
        .service_name{
          display: flex;
          align-items: center;
          .image_wrap{
            width: 100px;
            height: 100px;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
        .input_wrap{
          margin-top: 0;
          .token__form{
            display: flex;
            align-items: center;
            .input input {
              @include vw(350px, $pc_w, width);
						  @include vw(50px, $pc_w, height);
            }
          }
        }
        .btn_wrap{
          @include vw(20px, $pc_w, margin-left);
				  @include vw(250px, $pc_w, width);
          margin-top: 0;
        }
      }
      .section{
        @include vw(30px, $pc_w, margin-bottom);
      }
    }
  }
  // PC最大
  @media (min-width: $pc_w){
    .export-wrap_box{
      .title_head{
        padding-bottom: 20px;
        margin-bottom: 30px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        h2{
          font-size: 22px;
          margin-bottom: 0;
          padding:0 20px;
        }
        .service_name{
          display: flex;
          align-items: center;
          width: calc(100% - 35rem - 240px );

          .image_wrap{
            width: 100px;
            height: 100px;
            img{
              width: 100%;
              height: auto;
            }
          }
        }
        .input_wrap{
          margin-top: 0;
          .token__form{
            display: flex;
            align-items: center;
            .input input {
              width: 60rem;
              height: 5rem;
              font-size: 1.4rem;
            }
          }
        }
        .btn_wrap{
          margin-left: 2rem;
          margin-top: 0;
          width: 240px;
        }
      }
      .section{
        margin-bottom: 3rem;
      }
    }
  }
}

