* {
  word-break: break-word;
}

button {
  font-family: 'Roboto';
}

.black-color {
  color: #111947;
}

.text-content-color {
  color: $colorTextContent;
}

img {
  max-height: 100%;
  max-width: 100%;
}

.black-color2 {
  color: #000;
}

.opacity {
  opacity: 1;
}

.opacityNotLast {
  opacity: 0.45;
}

.size-16 {
  font-size: $default-font-size;
}

.text-ellipsis {
  @include text-ellipsis;
}

.default-padding {
  flex-grow: 1;
  padding: calc( 20vw / 4.14 ) calc( 15vw / 4.14 ) 0 calc( 15vw / 4.14 );
  max-width: 100%;
  @media #{$tablets} {
	  padding: calc( 20vw / 14 ) calc( 30vw / 14 ) 0;
	  max-width: 111rem;
	  margin: 0 auto;
	  box-sizing: border-box;
  }
  @media #{$desktops}{
	  padding-left: 0;
	  padding-right: 0;
  }
}

.fixed {
  position: fixed;
  width: 100%;
  z-index: 12;
}

.offset {
  @media #{$tablets} {
    width: 100%;
    height: 6rem;
  }
}

// using for avatars in /profile, etc...
.is-3x4 {
  @include aspect-ratio(3, 4);
}

.m-auto {
  margin: auto;
}

.ml-auto {
  margin-left: auto;
}

.mr-auto {
  margin-right: auto;
}

.mt-auto {
  margin-top: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.flex-1 {
  flex: 1;
}

.d-none {
  display: none;
}

.d-hidden {
  opacity: 0;
  visibility: hidden;
}
.text-center {
  text-align: center;
}

.loading {
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(255, 255, 255, 0.7);
  z-index: 500;
  > span {
    width: 150px;
    height: 150px;
  }
}
