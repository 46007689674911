.wrap-setting_modal {
  &__modal-password {
    position: absolute;
    top: 50%;
    left: 50%;
    display: block;
    padding: 2.6rem 1.5rem 4rem 1.5rem;
    width: 92vw;
    @media #{$tablets} {
      padding: 4.1rem 5.2rem 4.5rem 5.2rem;
      width: 62.3rem;
    }
    background-color: #fff;
    border-radius: 1rem;
    transform: translate(-50%, -50%);
    outline: transparent;
    &__header {
      text-align: center;
      &--space {
        display: none;
        @media #{$tablets} {
          display: block;
        }
      }
      &--title {
        display: inline-block;
        font-size: 2rem;
        color: $color-title;
        font-weight: bold;
        line-height: 1.5;
        @media #{$tablets} {
          font-size: 2.4rem;
        }
      }
      align-items: center;
      border-bottom: 1px solid #ccc;
      .md-close_modal {
        cursor: pointer;
        position: absolute;
        top: 2rem;
        right: 1.5rem;
        @media #{$tablets} {
          top: 2.6rem;
          right: 3rem;
        }
      }
    }
    &__body {
      padding: 3rem 0 3.8rem 0;
      @media #{$tablets} {
        padding: 3rem 0.2rem 3.8rem 0.2rem;
      }
      &--title-1 {
        font-size: $default-font-size;
        line-height: 1.875rem;
        color: $color-title;
        font-weight: 700;
        margin-bottom: 0.7rem;
        @media #{$tablets} {
          margin-bottom: 1.2rem;
        }
      }
      &--select {
        margin-bottom: 2.3rem;
        @media #{$tablets} {
          margin-bottom: 2.8rem;
        }
      }
      &--title-2 {
        font-size: $default-font-size;
        line-height: 1.875rem;
        color: $color-title;
        font-weight: 700;
        margin-bottom: 0.7rem;
        @media #{$tablets} {
          margin-bottom: 1.2rem;
        }
      }
      &--list-input {
        @media #{$tablets} {
          display: flex;
          justify-content: space-between;
        }
        &--item {
          &:not(:last-child) {
            margin-bottom: 2.3rem;
          }
          &__label {
            font-size: $default-font-size;
            line-height: 1.875rem;
            color: $color-title;
            font-weight: 700;
            margin-bottom: 0.7rem;
            @media #{$tablets} {
              margin-bottom: 1.2rem;
            }
          }
          &__content {
            display: flex;
            justify-content: space-between;
          }
        }
      }
    }
    &__footer {
      &--button {
        margin: 0 auto;
        width: 21.2rem;
        height: 5rem;
        background-color: $blue-main;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5rem;
        color: $white;
        font-size: 1.4rem;
        line-height: 2.52rem;
        cursor: pointer;
        &:hover {
          transform: translateY(0.5rem);
        }
      }
    }
  }

  &_button {
    .button-register {
      display: flex;
      flex-direction: row;
      justify-content: center;
      button {
        border: 0;
        border-radius: 1rem;
        border-radius: 5rem;
        outline: none;
        position: relative;
        transition: border-radius 3s;
        -webkit-transition: border-radius 3s;
        cursor: pointer;
      }
      .accept-button-area {
        position: relative;
        &--spinner {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .accept-button {
        background: $blue-main;
        color: $white;
      }
      .cancel-button {
        color: $body-text;
        box-sizing: border-box;
        border: 1px solid #ccc;
        margin-right: 1.5rem;
      }
    }
  }

  &__password-modal {
    .password__field-wrapper {
      .input-label-gutter_password {
        position: relative;
        .input-password_component__label--wrapper {
          display: flex;
          .input-password_component__label {
            @media #{$tablets} {
              padding-left: 2rem;
            }
          }
        }
        input {
          position: relative;
          @media #{$mobile} {
            max-width: 100%;
          }
        }
        .eye_password {
          position: absolute;
          cursor: pointer;
          @media #{$mobile} {
            right: 16px;
          }
        }
        .form-field-error-message {
          width: 100%;
        }
      }
    }
  }
}
