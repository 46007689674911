$gutter-textInput: 1rem;

.textarea {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #9f9f9f;
  font-size: 1.6rem;
  resize: none;
  &:not(:last-child) {
    margin-bottom: $gutter-textInput;
  }
  &__label--wrapper {
    width: 100%;
    margin-bottom: 1.2rem;
  }
  &__label {
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.875rem;
    display: inline-block;
    color: $color-title;
  }
  &__asterisk {
    color: red;
    font-size: 1.6rem;
    margin-left: 8px;
    display: inline-block;
  }
  &__field {
    width: 30rem;
    font-size: 1.4rem;
    font-weight: 400;
    display: block;
    border: 1px solid #9f9f9f;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    color: $body-text;
    background: transparent;
    border-radius: 0.5rem;
    position: relative;
    &.full {
      width: 100%;
    }
    &:hover {
      border: 1px solid #9f9f9f;
    }
    &:focus {
      outline: none !important;
      border: 1px solid #9f9f9f;
    }
    &::placeholder {
      color: $placeholder-input;
    }
    &:not(.full) {
      @media #{$mobiles} {
        width: 34.5rem;
      }
      @media #{$large-mobiles} {
        width: 50rem;
      }
    }
  }
}
