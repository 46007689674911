.template {
  display: flex;
  background-color: $white;
  justify-content: center;
  padding: 0rem 1.5rem 0 1.5rem;
  @media #{$tablets} {
    padding: 0rem 16.5rem 0 16.5rem;
  }
  &-wrap {
    flex: 1;
    background-color: $white;
  }
}
