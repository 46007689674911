.control-avatar {
  .wrap-sort {
    display: flex;
    margin-top: 2rem;
    height: 40px;
    //justify-content: flex-end;
    .wrap-sort-relative {
      position: relative;
      width: 100%;
      .input_sort {
        width: 17.3rem;
        @media #{$mobile} {
          width: 15.8rem;
        }
        div[class$='ValueContainer'] {
          height: 100%;
        }

        div[class$='IndicatorsContainer'] {
          position: relative;
          height: 100%;
        }
      }
      .right {
        text-align: right;
        white-space: nowrap;
        .right-relative {
          height: 100%;
          display: inline-block;
          vertical-align: top;
          margin-left: 0.5rem;
          text-align: left;
        }
      }
      
      .left {
        float: left;
      }
    }
    &_icon {      
      outline: none;
      cursor: pointer;
      background: none;
      border: none;
    }
  }
  &_content {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    align-items: center;
    justify-content: center;
    grid-column-gap: 3rem;
    grid-row-gap: 3rem;
    margin-top: 1rem;
    margin-bottom: 2.8rem;

    @media #{$mobile} {
      grid-template-columns: repeat(2, minmax(0, 1fr));
      grid-gap: 1.5rem;
    }
  }
}
