.signin {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-family: Roboto;
  margin-bottom: calc(35vw / 4.14);
  &__breadcrumb {
    width: 100%;
  }
  form {
    width: 100%;
  }
  &__forgot {
    font-size: calc(14vw / 4.14);
    text-align: right;
  }
  &__block {
    width: 100%;
    .input {
      position: relative;
      input {
        width: 100%;
        font-size: calc(16vw / 4.14);
        font-weight: 400;
        height: calc(48vw / 4.14);
        &:placeholder {
          font-weight: 400;
        }
      }
      .eye {
        right: calc(10vw / 4.14);
        top: 50%;
        transform: translateY(-50%);
        width: calc(16vw / 4.14);
        svg {
          width: 100%;
          height: auto;
          vertical-align: bottom;
        }
      }
    }
  }
  &__register {
    padding: 0 calc(37vw / 4.14);
    &--spinner {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
    button {
      height: calc(50vw / 4.14);
      font-size: calc(14vw / 4.14);
    }
  }
  &__list-btn {
    justify-content: center;
    padding: 0 calc(30vw / 4.14);
    .btn-item {
      button {
        width: 100%;
        height: calc(40vw / 4.14);
        .btn-link {
          width: calc(100% - (40vw / 4.14));
          font-size: calc(14vw / 4.14);
        }
        p {
          font-size: calc(14vw / 4.14);
        }
        &.twitter-button {
          svg {
            width: calc(40vw / 4.14);
          }
        }
        &.line-chat-button {
          .btn-wrap {
            width: calc(40vw / 4.14);
            svg {
              width: calc(30vw / 4.14);
              height: auto;
            }
          }
        }
        &.apple-button {
          svg {
            width: calc(26vw / 4.14);
            height: auto;
            text-align: center;
          }
        }
        &.facebook-button {
          svg {
            width: calc(26vw / 4.14);
            height: auto;
            text-align: center;
          }
        }
        &.google-button {
          svg {
            width: calc(38vw / 4.14);
            height: auto;
            text-align: center;
          }
        }
      }
      + .btn-item {
        margin-top: calc(15vw / 4.14);
      }
    }
  }
  &__aside {
    padding-top: calc(30vw / 4.14);
    margin-top: calc(30vw / 4.14);
    border-top: 1px solid #ccc;
    text-align: center;
    width: 100%;
    .text-normal-second {
      font-size: calc(16vw / 4.14);
      > span {
        @media #{$mobile} {
          display: block;
          line-height: initial;
        }
      }
      a {
        @media #{$mobile} {
          display: block;
        }
        .text-link {
          font-size: calc(16vw / 4.14);
        }
      }
    }
  }

  @media #{$tablets} {
    margin-bottom: calc(35vw / 9.92);
    &__forgot {
      font-size: calc(14vw / 9.92);
      a {
        color: #12c4c4;
      }
    }
    &__block {
      .input {
        input {
          font-size: calc(16vw / 9.92);
          font-weight: 400;
          height: calc(48vw / 9.92);
          padding: calc(10vw / 9.92);
        }
        .eye {
          right: calc(10vw / 9.92);
          top: 50%;
          transform: translateY(-50%);
          width: calc(16vw / 9.92);
          svg {
            vertical-align: middle;
          }
        }
      }
    }
    &__register {
      padding: 0 calc(37vw / 9.92);
      button {
        width: 100%;
        height: calc(50vw / 9.92);
        font-size: calc(14vw / 9.92);
      }
    }
    &__list-btn {
      display: flex;
      flex-wrap: wrap;
      padding: 0 calc(30vw / 9.92);
      .btn-item {
        width: 32%;
        margin: 0 2% 2% 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        &.mb-0 {
          margin-bottom: 0 !important;
        }
        button {
          width: 100%;
          min-width: auto;
          height: calc(40vw / 9.92);
          .btn-link {
            width: calc(100% - (40vw / 9.92));
            font-size: calc(14vw / 9.92);
          }
          p {
            font-size: calc(14vw / 9.92);
          }
          &.twitter-button {
            svg {
              width: calc(40vw / 9.92);
            }
          }
          &.line-chat-button {
            .btn-wrap {
              width: calc(40vw / 9.92);
              svg {
                width: calc(30vw / 9.92);
                height: auto;
              }
            }
          }
          &.apple-button {
            svg {
              width: calc(26vw / 9.92);
              height: auto;
              text-align: center;
            }
          }
          &.facebook-button {
            svg {
              width: calc(26vw / 9.92);
              height: auto;
              text-align: center;
            }
          }
          &.google-button {
            svg {
              width: calc(38vw / 9.92);
              height: auto;
              text-align: center;
            }
          }
        }
        + .btn-item {
          margin-top: 0;
        }
      }
    }
    &__aside {
      width: 100%;
      box-sizing: border-box;
      padding-top: calc(30vw / 9.92);
      margin-top: calc(30vw / 9.92);
      .text-normal-second {
        font-size: calc(16vw / 9.92);
        a {
          .text-link {
            font-size: calc(16vw / 9.92);
          }
        }
      }
    }
  }
  @media #{$small-desktops} {
    margin-bottom: 3.5rem;
    form {
      width: 50rem;
      display: block;
    }
    &__forgot {
      font-size: 1.4rem;
    }
    &__block {
      .input {
        input {
          font-size: 1.6rem;
          height: 4.8rem;
          padding: 1rem;
        }
        .eye {
          right: 1rem;
          width: 1.6rem;
        }
      }
    }
    &__register {
      padding: 0;
      button {
        width: 24rem;
        height: 5rem;
        font-size: 1.4rem;
      }
    }
    &__list-btn {
      padding: 0;
      display: flex;
      flex-wrap: wrap;
      .btn-item {
        width: 20rem;
        margin: 0 1.5rem 1.5rem 0;
        &:nth-child(3n) {
          margin-right: 0;
        }
        button {
          height: 4rem;
          .btn-link {
            width: calc(100% - 4rem);
            font-size: 1.4rem;
          }
          p {
            font-size: 1.4rem;
          }
          &.twitter-button {
            svg {
              width: 4rem;
            }
          }
          &.line-chat-button {
            .btn-wrap {
              width: 4rem;
              svg {
                width: 3rem;
              }
            }
          }
          &.apple-button {
            svg {
              width: 2.6rem;
            }
          }
          &.facebook-button {
            svg {
              width: 2.6rem;
            }
          }
          &.google-button {
            svg {
              width: 3.8rem;
            }
          }
        }
        + .btn-item {
          margin-top: 0;
        }
      }
    }
    &__aside {
      width: 63rem;
      margin: 0 auto;
      padding-top: 3rem;
      margin-top: 3rem;
      .text-normal-second {
        font-size: 1.6rem;
      }
    }
  }
}
