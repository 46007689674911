.line-chat-button {
  box-sizing: border-box;
  background-color: #00C300;
  display: flex;
  flex-direction: row;
  align-items: center;
  border-color: transparent;
  min-width: 20rem;
  border-radius: 0.5rem;
  font-size: 1.4rem;
  line-height: 1.641rem;
  padding: 0;
  border-width: 0;
  outline: none;
  height: 4rem;
  display: flex;
  cursor: pointer;
  @media #{$mobile} {
    min-width: 27rem;
  }
  .btn-wrap {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding:0 0.5rem;
    border-right: 1.5px solid #00B300;
  }
  .btn-link{
    flex: 1;
  }
  &:active {
    transform: translateY(1px);
  }
  a {
    color: $white;
    font-family: Roboto;
    margin-bottom: 0;
    margin-top: 0;
    @media #{$mobile} {
      left: 6.4rem;
    }
  }
}
