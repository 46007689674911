body {
  margin: auto;
  font-family: Roboto;
  color: $color-grey-dark;
  line-height: 1.7rem;
}

* {
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  &:not(.home) {
    overflow: hidden !important;
    body {
      overflow: auto !important;
    }
  }
}

button {
  border: none;
  outline: none;
  &:disabled {
    cursor: default;
  }
}

ul,
ol {
  margin: 0;
}

a {
  &:hover {
    text-decoration: underline;
  }
}

.main-color {
  color: $blue-main;
}

.sub-color {
  color: $sub-color;
}

.text-color {
  color: $body-text;
}

.text-sub-color {
  color: $color-text-normal;
}

.main-button {
  background: $blue-main;
  color: $white;
}

.sub-button {
  background: $sub-color;
  color: $white;
}

@for $i from 0 through 100 {
  .w-#{$i}-rem {
    width: #{$i}#{'rem'} !important;
  }
}

@for $i from 0 through 100 {
  .min-w-#{$i}-rem {
    min-width: #{$i}#{'rem'} !important;
  }
}

@for $i from 0 through 100 {
  .max-w-#{$i}-rem {
    min-width: #{$i}#{'rem'} !important;
  }
}

.wrapper {
  &__header {
    border-bottom: 1px solid #ccc;
    &__title {
      display: flex;
      align-items: center;
      svg {
        width: calc(28vw / 4.14);
        height: calc(28vw / 4.14);
        margin-right: calc(10vw / 4.14);
        @media #{$tablets} {
          width: calc(28vw / 14);
          height: calc(28vw / 14);
          margin-right: calc(10vw / 14);
        }
        @media #{$large-desktops} {
          width: 2.8rem;
          height: 2.8rem;
          margin-right: 1rem;
        }
      }
    }
    &__sub {
      font-weight: 500;
      color: #9f9f9f;
      font-size: calc(14vw / 4.14 );
      line-height: 1;
      @media #{$tablets} {
	      font-size: calc(14vw / 14 );
      }
      @media #{$large-desktops} {
	      font-size: 1.4rem;
      }
    }
  }
  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 2.8rem;
  }
}
@media #{$mobile} {
  .opacity {
    display: none;
  }
  .breadcrumb {
    display: none;
  }
}

.error-message {
  margin-top: 1rem;
  color: $red;
  white-space: pre-line;
}

blockquote {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 40px;
  margin-inline-end: 40px;
  font-style: italic;
  font-family: Georgia, Times, 'Times New Roman', serif;
  padding: 2px 0;
  border-style: solid;
  border-color: #ccc;
  border-width: 0;
  padding-left: 20px;
  padding-right: 8px;
  border-left-width: 5px;
}
