.menu {
  width: 100%;
  height: 100%;
  position: fixed;
  @include d-flex-column;
  top: 0;
  right: 0;
  z-index: 13;
  &::before {
    content: '';
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    z-index: 10;
    top: 0rem;
    @media #{$tablets} {
      visibility: visible;
      border-left: calc(4vw / 14) solid transparent;
      border-right: calc(4vw / 14) solid transparent;
      border-bottom: calc(4vw / 14) solid $white;
      right: calc(6vw / 14);
    }
    @media #{$large-desktops} {
      border-left: 0.4rem solid transparent;
      border-right: 0.4rem solid transparent;
      border-bottom: 0.4rem solid $white;
      right: 0.6rem;
    }
  }
  @media #{$tablets} {
    width: calc(332vw / 14);
    height: calc(63vw / 14);
    position: absolute;
    right: calc(-20vw / 14);
    top: calc(60vw / 14);
    max-height: calc(100vh - 8rem);
  }
  @media #{$large-desktops} {
    width: 33.2rem;
    height: 6.3rem;
    right: -2rem;
    top: 6rem;
  }
  @media #{$mobile} {
    background: #fff;
    .drop-up .Select-menu-outer {
      top: auto;
      bottom: 100%;
    }
  }

  .opacity {
    display: none;
    @media #{$tablets} {
      display: block;
      position: fixed;
      top: calc(60vw / 14);
      left: 0;
      width: 100vw;
      height: 100vh;
      background: rgba(0, 0, 0, 0.6);
      opacity: 0.6;
      z-index: 1;
    }
    @media #{$large-desktops} {
      top: 6rem;
    }
  }

  .content {
    height: 100%;
    @include d-flex-column;
    flex: 1;
    overflow: auto;
    @media #{$tablets} {
      overflow: visible;
      height: auto;
      z-index: 11;
      background: $white;
      border-radius: calc(10vw / 14);
      &::before {
        content: '';
        visibility: visible;
        width: 0;
        height: 0;
        border-left: calc(10vw / 14) solid transparent;
        border-right: calc(10vw / 14) solid transparent;
        border-bottom: calc(10vw / 14) solid $white;
        position: absolute;
        z-index: 10;
        bottom: 100%;
        right: calc(25vw / 14);
      }
    }
    @media #{$large-desktops} {
      border-radius: 1rem;
      &::before {
        border-left: 1rem solid transparent;
        border-right: 1rem solid transparent;
        border-bottom: 1rem solid $white;
        right: 2.5rem;
      }
    }

    &__header {
      width: 100%;
      position: relative;
      background-color: $white;
      border-bottom: 1px solid $gray-divider;
      &--close-icon {
        display: flex;
        background-color: #111947;
        align-items: center;
        height: calc(60vw / 4.14);
        position: sticky;
        top: 0;
        left: 0;
        svg {
          cursor: pointer;
          width: calc(22vw / 4.14);
          height: calc(22vw / 4.14);
        }

        &_logo {
          flex: 1;
          visibility: hidden;
        }
      }
      &--top {
        display: flex;
        padding: calc(20vw / 4.14) calc(20vw / 4.14) calc(10vw / 4.14) calc(20vw / 4.14);
        &__information {
          width: calc(100% - (80vw / 4.14));
          margin-left: calc(15vw / 4.14);
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: center;
        }
      }
      &--img {
        width: calc(80vw / 4.14);
        height: calc(80vw / 4.14);
        border-radius: calc(40vw / 4.14);
        object-fit: cover;
      }
      &--username {
        font-weight: 700;
        font-size: calc(20vw / 4.14);
        width: 100%;
        text-overflow: ellipsis;
        color: $color-title;
        overflow: hidden;
        white-space: nowrap;
        padding-bottom: calc(10vw / 4.14);
        font-size: calc(20vw / 4.14);
        line-height: 1.75;
      }
      &--btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
      }
      &--rank {
        width: calc(100vw / 4.14);
        height: calc(24vw / 4.14);
        font-size: $default-font-size-second;
        background-color: #c0c8cc;
        color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(20vw / 4.14);
        font-size: calc(14vw / 4.14);
        display: flex;
        justify-content: center;
        &.t002 {
          background-color: #e4d016;
        }
        &.t003 {
          background-color: #0ed7d7;
        }
      }
      &--bottom {
        display: flex;
        justify-content: space-between;
        padding: calc(15vw / 4.14) calc(60vw / 4.14) calc(15vw / 4.14) calc(50vw / 4.14);
        align-items: center;
        border-top: 1px solid $gray-divider;
        &__item {
          color: $body-text;
          width: 100%;
          height: calc(25vw / 4.14);
          text-align: center;
          font-size: $default-font-size;
          line-height: 1.75;
          display: flex;
          align-items: center;
          font-size: calc(16vw / 4.14);
          &:not(:last-child) {
            border-right: 1px solid $gray-divider;
          }
          &:not(:first-child) {
            justify-content: flex-end;
          }
          &--qty {
            display: inline-block;
            color: $blue-main;
            margin-left: calc(20vw / 4.14);
            font-weight: bold;
          }
        }
      }
      &--status {
        width: calc(100vw / 4.14);
        height: calc(24vw / 4.14);
        font-size: $default-font-size-second;
        line-height: 1.75;
        background-color: $sub-color;
        color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: calc(20vw / 4.14);
        margin-left: calc(10vw / 4.14);
        font-size: calc(14vw / 4.14);
        display: flex;
        justify-content: center;
      }
      @media #{$tablets} {
        border-top-left-radius: calc(10vw / 14);
        border-top-right-radius: calc(10vw / 14);

        &--close-icon {
          display: none;
        }
        &--top {
          display: flex;
          padding: calc(20vw / 14) calc(20vw / 14) calc(10vw / 14) calc(20vw / 14);
          &__information {
            width: calc(100% - (80vw / 14));
            margin-left: calc(15vw / 14);
          }
        }
        &--img {
          width: calc(80vw / 14);
          height: calc(80vw / 14);
          border-radius: calc(40vw / 14);
        }
        &--username {
          font-size: calc(20vw / 14);
          padding-bottom: calc(10vw / 14);
          font-size: calc(20vw / 14);
        }
        &--rank {
          width: calc(100vw / 14);
          height: calc(24vw / 14);
          border-radius: calc(20vw / 14);
          font-size: calc(14vw / 14);
        }

        &--bottom {
          padding: calc(15vw / 14) calc(60vw / 14) calc(15vw / 14) calc(50vw / 14);
          &__item {
            height: calc(25vw / 14);
            font-size: calc(16vw / 14);
            &--qty {
              margin-left: calc(20vw / 14);
            }
          }
        }
        &--status {
          width: calc(100vw / 14);
          height: calc(24vw / 14);
          border-radius: calc(20vw / 14);
          margin-left: calc(10vw / 14);
          font-size: calc(14vw / 14);
        }
      }
      @media #{$large-desktops} {
        border-top-left-radius: 1rem;
        border-top-right-radius: 1rem;
        &--top {
          display: flex;
          padding: 2rem 2rem 1rem 2rem;
          &__information {
            width: calc(100% - 9.5rem);
            margin-left: 1.5rem;
          }
        }
        &--img {
          width: 8rem;
          height: 8rem;
          border-radius: 4rem;
        }
        &--username {
          font-size: 2rem;
          padding-bottom: 1rem;
          font-size: 2rem;
        }
        &--rank {
          width: 50%;
          height: 2.4rem;
          border-radius: 2rem;
          font-size: 1.4rem;
        }
        &--bottom {
          padding: 1.5rem 6rem 1.5rem 5rem;
          &__item {
            height: 2.5rem;
            font-size: 1.6rem;
            &--qty {
              margin-left: 2rem;
            }
          }
        }
        &--status {
          width: 10rem;
          height: 2.4rem;
          border-radius: 2rem;
          margin-left: 1rem;
          font-size: 1.4rem;
        }
      }
    }
    &__body {
      margin-top: calc(10vw / 4.14);
      flex: 1;
      overflow: auto;

      &--part {
        &:first-child {
          padding-bottom: calc(10vw / 4.14);
        }
        &:last-child {
          padding-top: calc(10vw / 4.14);
        }
        &:not(:last-child) {
          border-bottom: 1px solid $gray-divider;
        }
        &__item {
          text-decoration: none;
          color: $body-text;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          cursor: pointer;
          padding: calc(10vw / 4.14) 0 calc(10vw / 4.14) calc(30vw / 4.14);
          &:hover {
            background-color: #f1f3f8;
          }

          &--icon {
            margin-right: calc(15vw / 4.14);
            display: flex;
            svg {
              fill: $icon-color;
              width: calc(24vw / 4.14);
              height: calc(24vw / 4.14);
            }
          }
          &--title {
            font-size: calc(14vw / 4.14);
          }

          &.disable {
            background: transparent;
            color: $gray-divider;
            cursor: default;
            svg {
              fill: $gray-divider;
            }
          }
        }
      }
      @media #{$tablets} {
        margin-top: calc(10vw / 14);
        border-radius: 0 0 calc(10vw / 14) calc(10vw / 14);
        overflow: hidden;
        &--part {
          &:first-child {
            padding-bottom: calc(10vw / 14);
          }
          &:last-child {
            padding-top: calc(10vw / 14);
          }
          &__item {
            padding: calc(10vw / 14) 0 calc(10vw / 14) calc(30vw / 14);
            &--icon {
              margin-right: calc(15vw / 14);
              svg {
                width: calc(24vw / 14);
                height: calc(24vw / 14);
              }
            }
            &--title {
              font-size: calc(14vw / 14);
            }
          }
        }
      }
      @media #{$large-desktops} {
        margin-top: 1rem;
        border-radius: 0 0 1rem 1rem;
        overflow: hidden;
        &--part {
          &:first-child {
            padding-bottom: 1rem;
          }
          &:last-child {
            padding-top: 1rem;
          }
          &__item {
            padding: 1rem 0 1rem 3rem;
            &--icon {
              margin-right: 1.5rem;
              svg {
                width: 2.4rem;
                height: 2.4rem;
              }
            }
            &--title {
              font-size: 1.4rem;
            }
          }
        }
      }
    }
    &__footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-top: 1px solid $gray-divider;
      padding: calc(20vw / 4.14) 0;
      &--text {
        font-size: 2rem;
        line-height: 1.875rem;
      }
      &--flag {
        width: 3.2rem;
        height: 3.2rem;
        background-color: #f0f0f0;
        border-radius: 1.6rem;

        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 1rem;
        &__content {
          width: 0.9rem;
          height: 0.9rem;
          border-radius: 0.45rem;
          background-color: #d80027;
        }
      }
      #react-select-3-input {
        position: absolute;
      }
      .css-2b097c-container {
        [class$='control'] {
          color: $body-text;
          height: calc(43vw / 4.14);
          font-size: calc(16vw / 4.14);
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          border-top: 1px solid #3132321a;
        }
        [class$='singleValue'] {
          svg {
            margin-right: calc(8vw / 4.14);
            height: 3.3rem;
            width: 3.3rem;
          }
          > span > span {
          }
        }
        [class$='menu'] {
          font-size: calc(16vw / 4.14);
          line-height: 1.485;
          width: calc(180vw / 4.14);
          svg {
            margin-right: calc(10vw / 4.14);
          }
        }
      }
      @media #{$tablets} {
        display: none;
      }
    }
  }
}

.fade-in {
  animation: fadeIn 0.15s ease-out;
  @media #{$tablets} {
    animation: fadeInDesktop 0.15s ease-out;
  }
}

.fade-out {
  animation: fadeOut 0.15s ease-out;
  @media #{$tablets} {
    animation: fadeOutDesktop 0.15s ease-out;
  }
}

@keyframes fadeIn {
  from {
    right: -100vw;
  }
  to {
    right: 0;
  }
}

@keyframes fadeOut {
  from {
    right: 0;
  }
  to {
    right: -100vw;
  }
}

@keyframes fadeInDesktop {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOutDesktop {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.hideBorder {
  border: none !important;
}

.menu-flags-button-header {
  color: $white !important;
  border: none !important;
  .ReactFlagsSelect-module_selectBtn__19wW7 {
    border: none !important;
    border-color: #fff !important;
    outline: none !important;
  }
  &:focus {
    outline: none !important;
  }
  &::after {
    border-top-color: $white !important;
    border-bottom-color: $white !important;
  }
}

.className-footer {
  .ReactFlagsSelect-module_fullWidthOptions__1XeR6 {
    top: -10rem !important;
  }
}

.ReactFlagsSelect-module_flagsSelect__2pfa2 {
  padding-bottom: 0 !important;
}
.ReactFlagsSelect-module_selectFlag__2q5gC > svg:first-child {
  border-radius: 100%;
  display: block;
  background-color: yellow;
}

.toggleMenu {
  display: flex;
  justify-content: center;
  align-items: center;
}
