.inforUser {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding-bottom: 20px;

  &__btn {
    display: flex;
    width: 200px;
    justify-content: space-between;
    button {
      &:first-child:not(:last-child),
      &:last-child:not(:first-child) {
        width: 95px;
      }
    }
  }
  img {
    width: 8rem;
    height: 8rem;
    object-fit: cover;
  }
  @media screen and (min-width: 1500px) {
    height: auto;
  }
  .infoAvatar {
    width: 8rem;
    height: 8rem;
    border-radius: 50%;
  }
  .nameUser {
    text-align: center;
    width: 70%;
    word-wrap: break-word;
  }
  .buttons-rank {
    color: #00a9a9;
    margin-bottom: 2rem;
    cursor: pointer;
    padding: 1rem 3rem;
    background-color: #fff;
    display: inline-block;
    text-align: center;
    vertical-align: middle;
    user-select: none;
    --btn-raise: 1rem;
    border: none;
    outline: none;
    clip-path: polygon(
      var(--btn-raise) 0%,
      calc(100% - var(--btn-raise)) 0%,
      100% 50%,
      calc(100% - var(--btn-raise)) 100%,
      var(--btn-raise) 100%,
      0 50%
    );
  }
}
