@import "../components/common/basic_style_v2.scss";

.status-wrap {
  display: flex;
  flex-direction: column;
  font-family: Roboto;
  color: $body-text;
  &_box {
    display: flex;
    justify-content: center;
  }
  &__label {
	  font-size: calc( 20vw / 4.14 );
  }
  &_content-main {
	  margin-top: calc( 10vw / 4.14 );
    p {
	    font-size: calc( 16vw / 4.14 );
	    margin-bottom: calc( 20vw / 4.14 );
	    line-height: 1.75;
      color: $body-text;
      font-weight: 400;
    }
  }
  @media #{$tablets}{
	  &__label {
		  font-size: calc( 20vw / 14 );
	  }
	  &_content-main {
		  margin-top: calc( 10vw / 14 );
	    p {
		    font-size: calc( 16vw / 14 );
		    margin-bottom: calc( 20vw / 14 );
	    }
	  }
	  .box-content{
		  display: flex;
		  justify-content: flex-start;
		  align-items: stretch;
		  .card-member{
			  width: 32%;
			  margin: 0 2% 2% 0;
			  &:nth-child(3n){margin-right: 0;}
		  }
	  }
  }
  @media #{$desktops}{
	  &_box{
		  max-width: 11.8rem;
		  margin: 0 auto;
	  }
	  &__label {
		  font-size: 2rem;
	  }
	  &_content-main {
		  margin-top: 1rem;
	    p {
		    font-size: 1.6rem;
		    margin-bottom: 2rem;
	    }
	  }
	  .box-content{
		  .card-member{
			  width: 28.5rem;
			  margin: 0 2% 2% 0;
		  }
	  }
  }
}

