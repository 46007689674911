.policy {
	color: #333;
	&__ttle{
		color: #111947;
		font-size: calc( 20vw / 4.14 );
		margin-bottom: calc( 10vw / 4.14 );
	}
	&__lead{
		margin-bottom: calc( 25vw / 4.14 );
		p{
			color: #32395a;
			font-size: calc( 16vw / 4.14 );
			line-height: 1.75;
		}
	}
	&__sec+&__sec{margin-top: calc( 30vw / 4.14 );}
	&__label{
		color: #111947;
		font-size: calc( 18vw / 4.14 );
		margin-bottom: calc(10vw / 4.14 );
		font-weight: bold;
	}
	&__content{
		color: #32395a;
		font-size: calc( 16vw / 4.14 );
		line-height: 1.75;
		p{
			font-size: calc( 16vw / 4.14 );
			line-height: 1.75;
		}
		p+*{
			margin-top: calc( 5vw / 4.14 );
		}
		p+p{margin-top: calc( 15vw / 4.14 );}
	}
	&__list{
    margin: 0;
    padding: 0;
    list-style: none;
		&--katakana{
      list-style: none;
      >li{
        counter-increment: counter1;
        position: relative;
        padding-left: 3ch;
        &::before {
	        position: absolute;
	        left: 0;
	        top: 0;
          content: counter(counter1, katakana) '）';
        }
      }
      li+li{margin-top: calc( 10vw / 4.14 );}
      &.list_style_katakana2{
				> li{
		      &::before {
		        content: counter(counter1,upper-alpha);
		      }
				}
      }
      
		}
		&--num{
			li{
				font-size: calc( 16vw / 4.14 );
				line-height: 1.75;
			}
			> li{
				font-size: calc( 16vw / 4.14 );
				line-height: 1.75;
				position: relative;
	      counter-increment: counter1;
	      padding-left: calc(30vw / 4.14 );
	      &::before {
		      position: absolute;
		      left: 0;
		      top: 0;
	        content: counter(counter1) '. ';
	      }
	      >ol{
					counter-reset: counter1;
		      margin-top: calc( 5vw / 4.14 );
	      }
			}
			> li+ li{
				margin-top: calc( 10vw / 4.14 );
			}
	    &.start {
	      counter-reset: counter2;
	      > li {
	        &:first-child {
	          counter-set: counter2 2;
	        }
	        counter-increment: counter2;
	
	        &::before {
	          content: counter(counter2) '. ';
	        }
	      }
	    }
			&-parentheses{
				> li{
					font-size: calc( 16vw / 4.14 );
					line-height: 1.75;
					position: relative;
		      counter-increment: counter1;
		      padding-left: calc(25vw / 4.14 );
		      &::before {
			      position: absolute;
			      left: 0;
			      top: 0;
		        content: counter(counter1) ' ）';
		      }
		      >ol{
						counter-reset: counter1;
			      margin-top: calc( 5vw / 4.14 );
		      }
				}
				> li+ li{
					margin-top: calc( 10vw / 4.14 );
				}
			}
			&-iroha{
				> li{
					font-size: calc( 16vw / 4.14 );
					line-height: 1.75;
					position: relative;
		      counter-increment: counter1;
		      padding-left: calc(25vw / 4.14 );
		      &::before {
			      position: absolute;
			      left: 0;
			      top: 0;
		        content: counter(counter1,katakana-iroha	);
		      }
		      >ol{
						counter-reset: counter1;
			      margin-top: calc( 5vw / 4.14 );
		      }
				}
				> li+ li{
					margin-top: calc( 10vw / 4.14 );
				}
				&.alpha{
					> li{
			      &::before {
			        content: counter(counter1,upper-alpha);
			      }
					}
				}
			}
		}
	}
	&__flame{
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		margin: calc( 10vw / 4.14 ) calc( 20vw / 4.14 );
		padding-bottom: calc( 10vw / 4.14 );
		table{
			width: 200%;
		}
	}
	&__table{
		border-collapse: collapse;
		border-spacing: 0;
		font-size: calc( 14vw / 4.14 );
		thead{
			tr{
				th{
			    background: #111947;
			    color: #fff;
			    padding: calc( 10vw / 4.14 ) 0;
			    text-align: center;
				}
			}
		}
		tbody{
			tr{
				th{
			    background: #111947;
			    color: #fff;
			    padding: calc( 10vw / 4.14 );
			    text-align: left;
			    vertical-align: middle;
				}
				td{
					border: 1px solid #ddd;
			    vertical-align: middle;
			    padding: calc( 10vw / 4.14 );
			    &.whitespace{white-space: nowrap;}
				}
			}
		}
	}
	@media#{$tablets} {
		&__ttle{
			font-size: calc( 20vw / 12);
			margin-bottom: calc( 10vw / 12);
		}
		&__lead{
			margin-bottom: calc( 25vw / 12);
			p{
				font-size: calc( 16vw / 12);
			}
		}
		&__sec+&__sec{margin-top: calc( 30vw / 12);}
		&__label{
			font-size: calc( 18vw / 12);
			margin-bottom: calc(10vw / 12);
		}
		&__content{
			font-size: calc( 16vw / 12);
			p{
				font-size: calc( 16vw / 12 );
			}
			p+*{
				margin-top: calc( 5vw / 12 );
			}
			p+p{margin-top: calc( 15vw / 12);}
		}
		&__list{
			&--katakana{
	      >li{
	        &::before {
	          content: counter(counter1, katakana) '）';
	        }
	      }
	      li+li{margin-top: calc( 10vw / 12);}
			}
			&--num{
				li{
					font-size: calc( 16vw / 12);
				}
				> li{
					font-size: calc( 16vw / 12);
		      padding-left: calc(30vw / 12);
		      >ol{
			      margin-top: calc( 5vw / 12);
		      }
				}
				> li+ li{
					margin-top: calc( 10vw / 12);
				}
				&-parentheses{
					> li{
						font-size: calc( 16vw / 12);
			      padding-left: calc(25vw / 12);
			      >ol{
				      margin-top: calc( 5vw / 12);
			      }
					}
					> li+ li{
						margin-top: calc( 10vw / 12);
					}
				}
				&-iroha{
					> li{
						font-size: calc( 16vw / 12 );
			      padding-left: calc(25vw / 12 );
			      >ol{
				      margin-top: calc( 5vw / 12 );
			      }
					}
					> li+ li{
						margin-top: calc( 10vw / 12 );
					}
				}
				
			}
		}
		&__flame{
			overflow: visible;
			margin: calc( 15vw / 12) 0;
			padding-bottom: 0;
			table{
				width: 100%;
			}
		}
		&__table{
			font-size: calc( 14vw / 12);
			&.is-fixed{
				width: 100%;
				table-layout: fixed;
			}
			thead{
				tr{
					th{
				    padding: calc( 10vw / 12) 0;
					}
				}
			}
			tbody{
				tr{
					th{
				    padding: calc( 10vw / 12 );
					}
					td{
				    padding: calc( 10vw / 12);
					}
				}
			}
		}
	}
	@media#{$desktops} {
		
		&__ttle{
			font-size: 2rem;
			margin-bottom: 1rem;
		}
		&__lead{
			margin-bottom: 2.5rem;
			p{
				font-size: 1.6rem;
			}
		}
		&__sec+&__sec{margin-top:3rem;}
		&__label{
			font-size: 1.8rem;
			margin-bottom: 1rem;
		}
		&__content{
			font-size: 1.6rem;
			p{
				font-size: 1.6rem;
			}
			p+*{
				margin-top: 1rem;
			}
			p+p{margin-top: 1.5rem;}
		}
		&__list{
			&--katakana{
	      li+li{margin-top: 1rem;}
			}
			&--num{
				li{
					font-size: 1.6rem;
				}
				> li{
					font-size: 1.6rem;
		      padding-left: 3rem;
		      >ol{
			      margin-top: .5rem;
		      }
				}
				> li+ li{
					margin-top: 1rem;
				}
				&-parentheses{
					> li{
						font-size: 1.6rem;
			      padding-left: 2.5rem;
			      >ol{
				      margin-top: .5rem;
			      }
					}
					> li+ li{
						margin-top: 1rem;
					}
				}
				&-iroha{
					> li{
						font-size: 1.6rem;
			      padding-left: 2.5rem;
			      >ol{
				      margin-top: .5rem;
			      }
					}
					> li+ li{
						margin-top: 1rem;
					}
				}
			}
		}
		&__flame{
			margin: 1.5rem 0;
		}
		&__table{
			font-size: 1.4rem;
			thead{
				tr{
					th{
				    padding: 1rem 0;
					}
				}
			}
			tbody{
				tr{
					tr{
						th{
					    padding: 1rem;
						}
					}
					
					td{
				    padding: 1rem;
					}
				}
			}
		}
		
	}
	
}

