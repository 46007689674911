.breadcrumb {
  align-self: flex-start;
  line-height: 2.2rem;
  width: 100%;
  margin-bottom: calc( 35vw / 4.14 );
  span {
    &:not(:last-child) {
      cursor: pointer;
    }
  }
  &__item {
    display: inline-block;
    font-size: calc( 12vw / 4.14 );
	  @media #{$tablets} {
	    font-size: calc( 12vw / 14 );
	  }
	  @media #{$large-desktops} {
	    font-size: 1.2rem;
	  }
    
  }
  &__split {
    padding-left: calc(8vw / 4.14 );
    padding-right: calc(8vw / 4.14 );
	  @media #{$tablets} {
	    padding-left: calc(8vw / 14 );
	    padding-right: calc(8vw / 14 );
	  }
	  @media #{$large-desktops} {
	    padding-left: .8rem;
	    padding-right: .8rem;
	  }
  }
  @media #{$tablets} {
    margin-bottom: calc( 35vw / 14 );
  }
  @media #{$large-desktops} {
	  margin-bottom: 3.5rem;
  }
}
